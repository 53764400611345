import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";

interface MuiButtonsProp {
  isInProgress: boolean;
  label: string;
  onClick?: () => void;
  style?: {};
}

export function MuiButton(Prop: MuiButtonsProp) {
  if (Prop.isInProgress) {
    return (
      <LoadingButton
        loading
        variant="outlined"
        style={Prop.style}
        onClick={Prop.onClick}
      >
        {Prop.label}
      </LoadingButton>
    );
  } else {
    return (
      <Button
        variant="contained"
        size="medium"
        style={Prop.style}
        onClick={Prop.onClick}
      >
        {Prop.label}
      </Button>
    );
  }
}
