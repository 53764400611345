import React from "react";
import { Box, BoxProps, Stack, Typography } from "@mui/material";
import { ArrowFillDownIcon } from "src/components/icons";

type PropsType = {
  text: string;
  link: string;
  direction?: string;
  isArrow: boolean;
};

export const MoreButton: React.FC<PropsType & BoxProps> = ({
  text,
  link,
  isArrow = true,
  direction = "right",
  ...rest
}) => {
  return (
    <Box
      sx={{
        width: 260,
        fontSize: 16,
        color: "#cb0400",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
        position: "relative",
        zIndex: 1,
        margin: "auto",
        "& a": {
          width: 260,
          height: 24,
          display: "block",
          color: "#cb0400",
          backgroundColor: "transparent",
          textDecoration: "none",
          padding: "19px 5% !important",
          zIndex: 2,
        },
        "& a:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          left: 0,
          width: "0%",
          height: "100%",
          transition: "0.3s width cubic-bezier(0.23, 1, 0.32, 1)",
          background: "#cb0400",
        },
        "& a:hover": {
          "& p": { color: "#fff", zIndex: 2 },
          "& svg path": { fill: "#fff" },
          "&:before": { width: "100%", zIndex: -1 },
        },
        ...rest,
      }}
    >
      <a href={link}>
        <Stack
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          fontWeight="bold"
        >
          {isArrow && direction === "left" && (
            <ArrowFillDownIcon
              sx={{
                width: 15,
                height: 15,
                mt: "6px",
                transform: "rotate(-90deg)",
                "& path": { fill: "#cb0400" },
              }}
            />
          )}
          <Typography fontWeight="bold">{text}</Typography>
          {isArrow && direction === "right" && (
            <ArrowFillDownIcon
              sx={{
                width: 15,
                height: 15,
                transform: "rotate(-90deg)",
                "& path": { fill: "#cb0400" },
              }}
            />
          )}
        </Stack>
      </a>
    </Box>
  );
};
export default MoreButton;
