import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  query,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";
import type { ReviewDataType } from "./sheet1";
import { firebaseConfig } from "../../auth";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

interface Employee {
  mailAddress: string;
  name: string;
  position: string;
}

export interface returnedDataType {
  reviewData: ReviewDataType | {};
  interReviewData: Score;
}

export interface uidNamePosition {
  uid: string;
  name: string;
  position: string;
}

export interface uidName {
  uid: string;
  name: string;
}

export interface Score {
  score: number[];
}

export interface DateAndSeason {
  startDate: string;
  endDate1: string;
  endDate2: string;
  isNewCollection: boolean;
}

export interface saveDateAndSeasonDataType {
  startDate: string;
  endDate1: string;
  endDate2: string;
  createdAt: Timestamp;
}

export const uploadReviewData = async (
  data: ReviewDataType | undefined,
  uid: string,
  position: string,
  seasonName: string
) => {
  /*把自评和复评数据上传到数据库*/
  const documentRef = doc(db, "reviews", seasonName, uid, position);
  await setDoc(documentRef, data, { merge: true });
};

export const fetchDataFromDatabase = async (
  Mod: number,
  uid: string | null,
  position: string | null,
  seasonName: string | null
): Promise<
  | ReviewDataType
  | uidName[]
  | undefined
  | uidNamePosition[]
  | Score
  | returnedDataType
  | number
  | string[]
> => {
  /*从数据库下载数据
   * Case1：获取所有用户信息(区分techAndBse)
   * Case2：根据uid获取自评和复评信息
   * Case3：获取员工的uid和职位信息
   * Case4：根据uid获取互评信息
   * Case5：获取用户的数量
   * Case6：获取设定的季度和日期等信息
   * Case7：获取所有用户信息(不区分techAndBse)
   * */
  const queryUsers = await getDocs(collection(db, "users"));
  let employeesInfo: uidNamePosition[] = [];

  switch (Mod) {
    case 1:
      try {
        for (const doc of queryUsers.docs) {
          const docData: Employee = doc.data() as Employee;
          if (docData.position === "techAndBse") {
            employeesInfo.push(
              { uid: doc.id, name: docData.name, position: "bse" },
              { uid: doc.id, name: docData.name, position: "tech" }
            );
          } else {
            employeesInfo.push({
              uid: doc.id,
              name: docData.name,
              position: docData.position,
            });
          }
        }
        return employeesInfo;
      } catch (error) {
        console.error("Error getting document:", error);
        break;
      }
    case 2:
      if (uid && position && seasonName) {
        let returnedData: returnedDataType = {
          reviewData: {},
          interReviewData: { score: [] },
        };
        try {
          const positionName = position === "tech" ? "Tech" : "BSE";
          const documentRef = doc(db, "reviews", seasonName, uid, positionName);
          const documentSnapshot = await getDoc(documentRef);

          if (documentSnapshot.exists()) {
            returnedData.reviewData = documentSnapshot.data();
          }
          const interData = await fetchDataFromDatabase(4, uid, null, seasonName);
          if (interData) {
            returnedData.interReviewData = interData as Score;
          }
          return returnedData;
        } catch (error) {
          console.error("Error getting document:", error);
          return undefined;
        }
      }
      break;
    case 3:
      try {
        const employeesData: uidName[] = [];
        for (const doc of queryUsers.docs) {
          const docData: Employee = doc.data() as Employee;
          if (["tech", "bse", "techAndBse"].includes(docData.position)) {
            employeesData.push({ uid: doc.id, name: docData.name });
          }
        }
        return employeesData;
      } catch (error) {
        console.error("Error getting document:", error);
        break;
      }
    case 4:
      if (uid) {
        try {
          let returnData: Score = { score: [] };
          const querySnapshot = await getDocs(
            collection(
              db,
              "interReviews",
              seasonName ? seasonName : "2024.Q1",
              "Data"
            )
          );
          querySnapshot.forEach((doc) => {
            if (uid === doc.id) {
              returnData = doc.data() as Score;
            }
          });
          return returnData;
        } catch (error) {
          console.error("Error getting document:", error);
          return undefined;
        }
      } else {
        return undefined;
      }
    case 5:
      try {
        let employeesQuantity: number = 0;
        queryUsers.forEach(() => {
          employeesQuantity++;
        });
        return employeesQuantity;
      } catch (error) {
        console.log("Error getting document:", error);
        return undefined;
      }
    case 6:
      try {
        let returnedSeasonList: string[] = [];
        const SeasonDocs = await getDocs(collection(db, "dateAndSeason"));
        SeasonDocs.forEach((docs) => {
          returnedSeasonList.push(docs.id);
        });
        return returnedSeasonList;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    case 7:
      try {
        for (const doc of queryUsers.docs) {
          const docData: Employee = doc.data() as Employee;
          employeesInfo.push({
            uid: doc.id,
            name: docData.name,
            position: docData.position,
          });
        }
        return employeesInfo;
      } catch (error) {
        console.error("Error getting document:", error);
        break;
      }
    default:
      return;
  }
};

export const uploadUsersToDatabase = async (
  uid: string,
  mailAddress: string,
  name: string,
  position: string
) => {
  /*把注册的用户信息上传到数据库*/
  const usersDataset = {
    mailAddress: mailAddress,
    name: name,
    position: position,
  };
  await setDoc(doc(db, "users", uid), usersDataset);
};

export const uploadInterReviews = async (
  uid: string[],
  score: number[],
  seasonName?: string
) => {
  /*把互评信息上传到数据库*/
  try {
    const querySnapshot = await getDocs(
      collection(
        db,
        "interReviews",
        seasonName ? seasonName : "2024.Q1",
        "Data"
      )
    );

    for (let i = 0; i < score.length; i++) {
      const usersDataset: { score: number[] } = {
        score: [score[i]],
      };

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (doc.id === uid[i]) {
          const score1: number[] = data.score;
          usersDataset.score = [...score1, score[i]];
        }
      });

      try {
        await setDoc(
          doc(
            db,
            "interReviews",
            seasonName ? seasonName : "2024.Q1",
            "Data",
            uid[i]
          ),
          usersDataset,
          { merge: true }
        );
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    return true;
  } catch (error) {
    console.log("错误：", error);
  }
};

export const findLatestDoc = async () => {
  /*找到最新的季度的信息*/
  const q = query(
    collection(db, "dateAndSeason"),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const querySnapshot = await getDocs(q);
  const latestDocument = querySnapshot.docs[0];
  if (latestDocument) {
    const latestDocumentId = latestDocument.id;
    const latestDocumentData =
      latestDocument.data() as saveDateAndSeasonDataType;
    const returnedData: {
      docId: string;
      docData: saveDateAndSeasonDataType;
    } = {
      docId: latestDocumentId,
      docData: latestDocumentData,
    };
    return returnedData;
  } else {
    return null;
  }
};
const nextQuarter = (input: string) => {
  const [yearString, quarterString] = input.split("."); // 分割字符串
  const year = parseInt(yearString); // 将年份部分解析为整数
  const quarter = parseInt(quarterString.slice(1)); // 去除 "Q" 后，将季度部分解析为整数

  let nextYear = year;
  let nextQuarter = quarter + 1;

  if (nextQuarter > 4) {
    nextQuarter = 1;
    nextYear++;
  }

  return `${nextYear}.Q${nextQuarter}`;
};
export const uploadDateAndSeason = async (data: DateAndSeason) => {
  /*将日期和季度信息上传到数据库*/
  const latestDoc = await findLatestDoc();
  const saveData: saveDateAndSeasonDataType = {
    startDate: data.startDate,
    endDate1: data.endDate1,
    endDate2: data.endDate2,
    createdAt: Timestamp.now(),
  };
  if (latestDoc) {
    if (data.isNewCollection) {
      const season = nextQuarter(latestDoc.docId);
      setDoc(doc(db, "dateAndSeason", season), saveData).catch((Error) => {
        console.log(Error);
      });
    } else {
      setDoc(doc(db, "dateAndSeason", latestDoc.docId), saveData).catch(
        (Error) => {
          console.log(Error);
        }
      );
    }
  } else {
    setDoc(doc(db, "dateAndSeason", "2024.Q1"), saveData).catch((Error) => {
      console.log(Error);
    });
  }
};
