import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export function BasicCard(
  title: string,
  date: string | number | undefined,
  firstCard?: boolean
) {
  if (firstCard) {
    return (
      <Card
        sx={{
          minHeight: 110,
          minWidth: 200,
          marginTop: "20px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: 14, marginTop: "15px" }}
            color="text.secondary"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography variant="h5" component="div">
            {date}
          </Typography>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card
        sx={{
          minHeight: 110,
          minWidth: 200,
          marginLeft: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: 14, marginTop: "15px" }}
            color="text.secondary"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography variant="h5" component="div">
            {date}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}
