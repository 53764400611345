import React, {useEffect, useState} from "react";
import {Table} from "antd";
import type {TableColumnsType} from "antd";
import {bseSheet,category2} from "../common";
import {ReceiveSheetProps,DataType} from "./sheet3";
import {ReviewDataType} from "@src/components/home/sheet1";


const categoryNumber: number = 4;

export const BossBSE: React.FC<ReceiveSheetProps> = ({
                                                         downloadedData,
                                                         interReviewData,
                                                         isFullscreen,
                                                         isBossPage
                                                     }) => {
    const [tableData, setTableData] = useState<DataType[]>([]);

    const columns: TableColumnsType<DataType> = [
        {
            title: "自我评价",
            children: [
                {
                    title: "编号",
                    dataIndex: "no",
                    key: "no",
                    width: "5%",
                    align: "center",
                    render: (text) => (
                        <div style={{textAlign: "center"}}>
                            <strong>{text}</strong>
                        </div>
                    ),
                },
                {
                    title: "技术人员评估",
                    children: [
                        {
                            title: "类别",
                            dataIndex: "category",
                            key: "category",
                            width: "10%",
                            align: "center",
                            render: (text) => (
                                <div style={{textAlign: "center"}}>
                                    <strong>{text}</strong>
                                </div>
                            ),
                        },
                        {
                            title: "评价项目",
                            dataIndex: "items",
                            key: "items",
                            align: "center",
                            width: isBossPage?"33%":"46%",
                            render: (text: string) => {
                                const sections: string[] = text.split(/([：。])/);
                                return (
                                    <div
                                        style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {sections.map((section: string, index: number) => {
                                            if (index % 4 < 2) {
                                                return <strong key={index}>{section}</strong>;
                                            } else {
                                                return section;
                                            }
                                        })}
                                    </div>
                                );
                            },
                        },
                    ],
                },
                {
                    title: "自我评价",
                    dataIndex: "self",
                    key: "self",
                    align: "center",
                    width: isBossPage?"4%":"5%",
                    render: (text) => (
                        <div
                            style={{
                                whiteSpace: "pre-wrap",
                                fontSize: "large",
                                textAlign: "center",
                            }}
                        >
                            {text}
                        </div>
                    ),
                },
            ],
        },
        {
            title: "Boss复评",
            children: [
                {
                    title: "CEO评价",
                    dataIndex: "ceo",
                    key: "ceo",
                    width: isBossPage?"4%":"5%",
                    align: "center",
                    render: (text) => (
                        <div
                            style={{
                                whiteSpace: "pre-wrap",
                                fontSize: "large",
                                textAlign: "center",
                            }}
                        >
                            {text}
                        </div>
                    ),
                },
                {
                    title: "CTO评价",
                    dataIndex: "cto",
                    key: "cto",
                    width: isBossPage?"4%":"5%",
                    align: "center",
                    render: (text) => (
                        <div
                            style={{
                                whiteSpace: "pre-wrap",
                                fontSize: "large",
                                textAlign: "center",
                            }}
                        >
                            {text}
                        </div>
                    ),
                },
            ],
        },
        {
            title: "文字评价",
            dataIndex: "exc",
            key: "exc",
            width: isBossPage?"40%":"25%",
            align: "center",
            render: (text:string[],index) => {
                if (index.key < categoryNumber-1){
                    return(
                        <div
                            style={{
                                whiteSpace: "pre-wrap",
                                fontSize: "12px",
                                textAlign: "left",
                                wordWrap: 'break-word'
                            }}
                        >
                            <li>
                                <strong><p>员工留言：</p></strong>
                                {text && text[0] ? (
                                    <>
                                        {text[0]}
                                        <hr/>
                                    </>
                                ) : ''}
                            </li>
                            <li>
                                <strong><p>CEO留言：</p></strong>
                                {text && text[1] ? (
                                    <>
                                        {text[1]}
                                        <hr/>
                                    </>
                                ) : ''}
                            </li>
                            <li>
                                <strong><p>CTO留言：</p></strong>
                                {text && text[2] ? (
                                    <>
                                        {text[2]}
                                        <hr/>
                                    </>
                                ) : ''}
                            </li>
                        </div>
                    )
                } else if (index.key === categoryNumber - 1) {
                    return (
                        <div
                            style={{
                                whiteSpace: "pre-wrap",
                                fontSize: "12px",
                                textAlign: "left",
                                wordWrap: 'break-word'
                            }}
                        >
                            <strong>{text && text[0] ? text[0] : ''}</strong>
                        </div>
                    )
                }
            }
        },
    ];
    useEffect(() => {
        function extractProperties(reviewData: ReviewDataType,interReviewData:number[]) {
            const selfList: string[] = [];
            const ceoList: string[] = [];
            const ctoList: string[] = [];
            const remarkList: string[][] = [];
            let returnRemarkList: string[][] = [];

            let categoryOrder = [];
            for (let i = 0; i < categoryNumber-1 ; i++) {
                const category = "category"+ (i+1).toString();
                categoryOrder.push(category);

                const categoryData = reviewData[category] || {};
                selfList.push(categoryData.self || "");
                ceoList.push(categoryData.ceo || "");
                ctoList.push(categoryData.cto || "");

                const reviews = [
                    categoryData.reviewFromEmployees,
                    categoryData.reviewFromCeo,
                    categoryData.reviewFromCto,
                ];
                remarkList.push(reviews);
            }
            console.log(remarkList);
            let formattedInterReviewDataList:string[] = [];
            if (interReviewData) {
                let formattedInterReviewData:string = '';
                formattedInterReviewData = interReviewData.map((number, index) => {
                    if (index < interReviewData.length - 1) {
                        return number.toString() + ',';
                    } else {
                        return number.toString();
                    }
                }).join('');
                formattedInterReviewDataList.push(formattedInterReviewData);
            }
            returnRemarkList = [...remarkList, formattedInterReviewDataList]
            return { selfList, ceoList, ctoList, returnRemarkList };
        }

        if (downloadedData) {
            const data: DataType[] = [];
            for (let i = 0; i < categoryNumber; i++) {
                data.push({
                    key: i,
                    no: i + 1,
                    items: bseSheet[i],
                    self: extractProperties(downloadedData,interReviewData).selfList[i],
                    ceo: extractProperties(downloadedData,interReviewData).ceoList[i],
                    cto: extractProperties(downloadedData,interReviewData).ctoList[i],
                    category: category2[i],
                    exc: extractProperties(downloadedData,interReviewData).returnRemarkList[i],
                });
            }
            setTableData(data);
        }
    }, [downloadedData,interReviewData,isFullscreen,isBossPage]);

    return (
        <>
            <Table columns={columns} dataSource={tableData} bordered size="middle"/>
        </>
    );
};
