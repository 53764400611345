import React from "react";
import { Box, BoxProps, keyframes } from "@mui/material";

type PropsType = {
  arrow?: string;
  children: React.ReactNode;
  isLine?: boolean;
};

const moveLeftColor = keyframes`
    0% {
        right: 50%;
        width: 1px;
        height: 0;
    }
    25% {
        right: 50%;
        width: 1px;
        height: 100%;
    }
    65% {
        right: 50%;
        width: 50%;
        height: 100%;
    }
    100% {
        right: 100%;
        width: 50%;
        height: 100%;
    }
`;

const moveRightColor = keyframes`
  0% {
		left: 50%;
		width: 1px;
		height: 0;
	}
	25% {
		left: 50%;
		width: 1px;
		height: 100%;
	}
	65% {
		left: 50%;
		width: 50%;
		height: 100%;
	}
	100% {
		left: 100%;
		width: 50%;
		height: 100%;
	}
`;

export const BoardTitle: React.FC<PropsType & BoxProps> = ({
  children,
  arrow = "left",
  isLine = false,
  ...rest
}) => {
  return (
    <Box
      sx={{
        width: "auto",
        pb: "40px",
        "@media screen and (max-width: 900px)": { pb: "20px" },
        position: "relative",
        left: 0,
        color: "#000",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        "&:after": {
          content: '""',
          width: 40,
          height: 3,
          display: "block",
          position: "absolute",
          left: arrow === "left" ? "" : arrow === "center" ? "50%" : "",
          transform:
            arrow === "left"
              ? ""
              : arrow === "center"
              ? "translate(-50%, 0)"
              : "",
          bottom: 0,
          background:
            "linear-gradient(to right, #cb0400 50%, #cb0400 50%, #0f158c 50%)",
        },
        "& .open_lines": {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        },
        "& .open_lines:before, .open_lines:after": {
          content: '""',
          position: "absolute",
          zIndex: 1,
          top: 0,
          width: "1px",
        },
        "& .open_lines:before": {
          background: "#cb0400",
          animation: `1s ${moveLeftColor} cubic-bezier(0.23, 1, 0.32, 1) forwards`,
        },
        "& .open_lines:after": {
          background: "#0f158c",
          animation: `1s ${moveRightColor} cubic-bezier(0.23, 1, 0.32, 1) forwards`,
        },
        ...rest,
      }}
    >
      {children}
      {isLine && <span className="open_lines"></span>}
    </Box>
  );
};
export default BoardTitle;
