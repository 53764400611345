import React, {useEffect, useState} from "react";
import {Table, Flex, Space, Input, Select, notification} from "antd";
import type {TableColumnsType} from "antd";
import {tecSheet, category} from "../common";
import {uploadReviewData} from "./dataExchange";
import {InputSheetProps} from "./sheet2";
import {getSeasonNumber} from "../home/Time";
import {MuiButton} from "../icons/MuiButtons";
import runes from "runes2";

export interface DataType {
    key: React.Key;
    no: number;
    items: string;
    category: string;
}

export type ReviewDataType = {
    [category: string]: {
        [key: string]: string;
    };
};

export type remarkType = {
    reviewFromEmployees?: string,
    reviewFromCeo?: string,
    reviewFromCto?: string
}

const {TextArea} = Input;
const categoryNumber: number = 4;
const filterOption = (
    input: string,
    option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const selectNumber = [
    {value: "1", label: "1分（Basic）： 表示员工未达到预定工作目标的基本水平。在工作表现、质量、个人发展及团队责任方面表现明显不足，需大幅改善以满足岗位基础要求。"},
    {value: "2", label: "2分（Developing）： 员工展现出发展潜力，已开始超越入门级要求，但对于达到全面标准要求的职业技能、工作效率和团队贡献仍需进一步提升和完善。"},
    {value: "3", label: "3分（Competent）： 员工的工作表现、质量、个人成长及团队责任均满足公司设定的职位标准。表明员工能够稳定地完成职责要求，并且基本达到了预期目标。"},
    {value: "4", label: "4分（Excellent）： 员工在各个考核领域均超越常规标准，呈现出较高水平的工作成果，积极参与额外工作和团队协作，表现出积极的企业大局观和显著的职业素养。"},
    {value: "5", label: "5分（Outstanding）： 员工在各项评核标准上的表现卓越非凡，成为模范，能在工作表现、质量、个人成长和团队责任上对公司产生深远影响，是所有同事学习的典范。"},
];

const selectCheck = (data: ReviewDataType | undefined, reviewFromDatabaseList: string[]) => {
    const filteredList = reviewFromDatabaseList.filter(item => item !== undefined);
    if (data) {
        const categories = Object.keys(data);
        return categories.length === categoryNumber || categoryNumber === filteredList.length;
    } else {
        return categoryNumber === filteredList.length;
    }
};

export const Tech: React.FC<InputSheetProps> = ({
                                                    employeeUid,
                                                    permission,
                                                    isGoToUploadSuccessPage,
                                                    isTechAndBse,
                                                    date,
                                                    seasonName,
                                                    review,
                                                    isBossPage
                                                }) => {
    const [myData, setMyData] = useState<ReviewDataType>();
    const [api, contextHolder] = notification.useNotification();
    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const remarkNameList: string[] = [
        "reviewFromEmployees",
        "reviewFromCeo",
        "reviewFromCto",
    ];
    const [selfValue, setSelfValue] = useState<string[]>([]);
    const [ceoValue, setCeoValue] = useState<string[]>([]);
    const [ctoValue, setCtoValue] = useState<string[]>([]);
    const [remarkValue, setRemarkValue] = useState<remarkType[]>([]);

    const collectionProgress = getSeasonNumber(
        date
            ? date
            : {
                startDate: "",
                endDate1: "",
                endDate2: "",
            }
    );

    useEffect(() => {
        let selfList: string[] = [];
        let ceoList: string[] = [];
        let ctoList: string[] = [];
        let remarkList: remarkType[] = [];
            if (review) {
                for (let i = 0; i < categoryNumber; i++) {
                    const dynamicKey: string = `category${i + 1}`;
                    if (review[dynamicKey]) {
                        let remark: remarkType = {};
                        selfList.push(review[dynamicKey].self);
                        ceoList.push(review[dynamicKey].ceo);
                        ctoList.push(review[dynamicKey].cto);
                        if (review[dynamicKey].reviewFromEmployees) remark.reviewFromEmployees = review[dynamicKey].reviewFromEmployees;
                        if (review[dynamicKey].reviewFromCeo) remark.reviewFromCeo = review[dynamicKey].reviewFromCeo;
                        if (review[dynamicKey].reviewFromCto) remark.reviewFromCto = review[dynamicKey].reviewFromCto;
                        remarkList.push(remark);
                    }
            }
        }
        setSelfValue(selfList);
        setCeoValue(ceoList);
        setCtoValue(ctoList);
        setRemarkValue(remarkList);
    }, [review]);
    const selectUploadButton = () => {
        if (collectionProgress !== 1 && permission === 1) {
            return;
        } else {
            return (
                <Flex gap="small" wrap="wrap">
                    <MuiButton
                        isInProgress={isInProgress}
                        label={"上传数据"}
                        onClick={handleSaveAndRedirect}
                    />
                </Flex>
            );
        }
    };
    const saveToHook = (key1: React.Key, key2: string, value: string) => {
        setMyData((prevData) => {
            const updatedData: ReviewDataType = {...prevData};

            const numKey1 = parseInt(key1 as string, 10);
            const categoryKey = `category${numKey1 + 1}` as keyof typeof updatedData;

            if (value !== "" || ["reviewFromEmployees","reviewFromCeo","reviewFromCto"].includes(key2)) {
                const newData = {[key2]: value};
                updatedData[categoryKey] = {...updatedData[categoryKey], ...newData};
            }
            return updatedData;
        });
    };

    const openNotification = () => {
        api.open({
            message: "提示",
            description: "评价上传成功",
            duration: 3,
        });
    };

    const handleSaveAndRedirect = () => {
        setIsInProgress(true);
        if (selectCheck(myData, permission === 1 ? selfValue : permission === 2 ? ceoValue : ctoValue)) {
            if (myData) {
                console.log("MY1",myData);
                uploadReviewData(myData, employeeUid, "Tech", seasonName).then(() => {
                    openNotification();
                    setIsInProgress(false);
                    if (isGoToUploadSuccessPage) {
                        if (isTechAndBse) {
                            window.localStorage.setItem("secondTime", "true");
                            window.location.href = "/evaluation/front";
                        } else {
                            window.location.href = "/evaluation/uploadSuccess";
                        }
                    }
                });
            } else {
                openNotification();
                setIsInProgress(false);
                if (isGoToUploadSuccessPage) {
                    if (isTechAndBse) {
                        window.localStorage.setItem("secondTime", "true");
                        window.location.href = "/evaluation/front";
                    } else {
                        window.location.href = "/evaluation/uploadSuccess";
                    }
                }
            }
        } else {
            setIsInProgress(false);
            alert("您有未填写的项目");
        }
    };

    const columns: TableColumnsType<DataType> = [
        {
            title: "自我评价",
            children: [
                {
                    title: "编号",
                    dataIndex: "no",
                    key: "no",
                    width: "5%",
                    align: "center",
                    render: (text) => (
                        <div style={{textAlign: "center"}}>
                            <strong>{text}</strong>
                        </div>
                    ),
                },
                {
                    title: "技术人员评估",
                    children: [
                        {
                            title: "类别",
                            dataIndex: "category",
                            key: "category",
                            width: isGoToUploadSuccessPage?"5%":"7%",
                            align: "center",
                            render: (text) => (
                                <div style={{textAlign: "center"}}>
                                    <strong>{text}</strong>
                                </div>
                            ),
                        },
                        {
                            title: "评价项目",
                            dataIndex: "items",
                            key: "items",
                            align: "center",
                            width: isBossPage?"33%":"39%",
                            render: (text: string) => {
                                const sections: string[] = text.split(/([：。])/);
                                return (
                                    <div
                                        style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: isGoToUploadSuccessPage?"12px":"14px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {sections.map((section: string, index: number) => {
                                            if (index % 4 < 2) {
                                                return <strong key={index}>{section}</strong>;
                                            } else {
                                                return section;
                                            }
                                        })}
                                    </div>
                                );
                            },
                        },
                    ],
                },
                {
                    title: "自我评价",
                    dataIndex: "self",
                    key: "self",
                    align: "center",
                    width: isBossPage?"6%":"8%",
                    render: (_, record, index) => (
                        permission === 1 && collectionProgress === 1 ? (
                            <Space>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        saveToHook(record.key, "self", value);
                                        const updatedSelfValue = [...selfValue];
                                        updatedSelfValue[index] = value;
                                        setSelfValue(updatedSelfValue);
                                    }}
                                    filterOption={filterOption}
                                    options={selectNumber}
                                    value={selfValue[index]}
                                    style={{width: 60}}
                                    disabled={permission !== 1 || collectionProgress !== 1}
                                />
                            </Space>) : (
                            <div style={{fontSize:"large"}}>
                                {selfValue[index]}
                            </div>
                        )
                    ),
                },
            ],
        },
        {
            title: "Boss复评",
            children: [
                {
                    title: "CEO评价",
                    dataIndex: "ceo",
                    key: "ceo",
                    width: isBossPage?"6%":"8%",
                    align: "center",
                    render: (_, record, index) => (
                        permission === 2 ? (
                            <Space>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        saveToHook(record.key, "ceo", value);
                                        const updatedCeoValue = [...ceoValue];
                                        updatedCeoValue[index] = value;
                                        setCeoValue(updatedCeoValue);
                                    }}
                                    value={ceoValue[index]}
                                    filterOption={filterOption}
                                    options={selectNumber}
                                    style={{width: 60}}
                                    disabled={permission !== 2}
                                />
                            </Space>) : (
                            <div style={{fontSize:"large"}}>
                                {ceoValue[index]}
                            </div>
                        )
                    ),
                },
                {
                    title: "CTO评价",
                    dataIndex: "cto",
                    key: "cto",
                    width: isBossPage?"6%":"8%",
                    align: "center",
                    render: (_, record, index) => (
                        permission === 3 ? (
                            <Space>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        saveToHook(record.key, "cto", value);
                                        const updatedCtoValue = [...ctoValue];
                                        updatedCtoValue[index] = value;
                                        setCtoValue(updatedCtoValue);
                                    }}
                                    value={ctoValue[index]}
                                    filterOption={filterOption}
                                    options={selectNumber}
                                    style={{width: 60}}
                                    disabled={permission !== 3}
                                />
                            </Space>) : (
                            <div style={{fontSize:"large"}}>
                                {ctoValue[index]}
                            </div>
                        )
                    ),
                },
            ],
        },
        {
            title: "文字评价",
            dataIndex: "exc",
            key: "exc",
            width: isBossPage?"41%":"35%",
            align: "center",
            render: (_, record, index) => (
                <div>
                    <div style={{fontSize:"x-small"}}>
                            {permission !== 1 && <div style={{
                                textAlign: "left",
                            }}>{`员工留言：${remarkValue[index]?.reviewFromEmployees || "无"}`}</div>}
                            {permission !== 2 && <div style={{
                                textAlign: "left",
                            }}>{`CEO留言：${remarkValue[index]?.reviewFromCeo || "无"}`}</div>}
                            {permission !== 3 && <div style={{
                                textAlign: "left",
                            }}>{`CTO留言：${remarkValue[index]?.reviewFromCto || "无"}`}</div>}
                    </div>
                    <div style={{marginBottom:"10px"}}>
                        <TextArea
                            rows={4}
                            maxLength={300}
                            placeholder = {"我的留言"}
                            value={
                                permission === 1 ? (remarkValue[index]?.reviewFromEmployees || '') :
                                    permission === 2 ? (remarkValue[index]?.reviewFromCeo || '') :
                                        (remarkValue[index]?.reviewFromCto || '')
                            }
                            count={{
                                show: true,
                                max: 300,
                                strategy: (txt: string) => txt.length,
                                exceedFormatter: (txt, {max}) => runes(txt).slice(0, max).join(''),
                            }}
                            onBlur={(e) =>{
                                saveToHook(
                                    record.key,
                                    remarkNameList[permission - 1],
                                    e.target.value
                                )}
                            }
                            onChange={(e) => {
                                const newValue = e.target.value;
                                setRemarkValue(prevRemarkValue => {
                                    const updatedRemarkValue = {...prevRemarkValue[index]};
                                    if (permission === 1) {
                                        updatedRemarkValue.reviewFromEmployees = newValue;
                                    } else if (permission === 2) {
                                        updatedRemarkValue.reviewFromCeo = newValue;
                                    } else {
                                        updatedRemarkValue.reviewFromCto = newValue;
                                    }
                                    const newRemarkValue = [...prevRemarkValue];
                                    newRemarkValue[index] = updatedRemarkValue;
                                    return newRemarkValue;
                                });
                            }}
                            disabled={collectionProgress !== 1 && permission === 1}
                        />
                    </div>
                </div>
            ),
        },
    ];

    const data: DataType[] = [];
    for (let i = 0; i < categoryNumber; i++) {
        data.push({
            key: i,
            no: i + 1,
            items: tecSheet[i],
            category: category[i],
        });
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                size="middle"
            />
            <div style={{alignContent:"center",justifyContent:"center",alignItems:"center",display:"flex"}}>
                {contextHolder}
                {selectUploadButton()}
            </div>
        </>
    );
};
