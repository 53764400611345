import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { EmployeesPage, BossPage, UploadSuccessPage } from "./components/pages";
import LoginPage from "./components/pages/LoginPage";
import {
  AboutPage,
  History,
  HomePage,
  JoinDetailPage,
  JoinPage,
  News,
  PhilosophyPage,
  ProductDetail,
  ProductsPage,
} from "src/components/pages";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import "src/locales/en.json";
import "src/locales/ja.json";
import "src/locales/zh.json";

export const Home: React.FC = () => {
  const [language, setLanguage] = useState(navigator.language);

  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(navigator.language);
    };

    window.addEventListener("languagechange", handleLanguageChange);

    return () => {
      window.removeEventListener("languagechange", handleLanguageChange);
    };
  }, []);

  i18n.init({
    lng: localStorage.getItem("language") ?? language.slice(0, 2),
    resources: {
      en: {
        translation: require("./locales/en.json"),
      },
      ja: {
        translation: require("./locales/ja.json"),
      },
      zh: {
        translation: require("./locales/zh.json"),
      },
    },
    fallbackLng: language.slice(0, 2),
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/history" element={<History />} />
          <Route path="/news" element={<News />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/recruit" element={<JoinPage />} />
          <Route path="/recruit/detail" element={<JoinDetailPage />} />
          <Route path="/philosophy" element={<PhilosophyPage />} />
          <Route path="/service" element={<ProductsPage />} />
          <Route path="/service/detail" element={<ProductDetail />} />
          <Route path="/evaluation/front" element={<EmployeesPage />} />
          <Route path="/evaluation/back" element={<BossPage />} />
          <Route path="/evaluation/login" element={<LoginPage />} />
          <Route
            path="/evaluation/uploadSuccess"
            element={<UploadSuccessPage />}
          />
        </Routes>
      </Router>
    </I18nextProvider>
  );
};

export default Home;
