export const firebaseConfig = {
  // Your web app's Firebase configuration here
  // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
  apiKey: "AIzaSyCw7tGACVZUJn0LhZ8wKVbL4cYcZqRqUNo",
  authDomain: "shuluntc-hp.firebaseapp.com",
  projectId: "shuluntc-hp",
  storageBucket: "shuluntc-hp.appspot.com",
  messagingSenderId: "885247399814",
  appId: "1:885247399814:web:2001d912a58c3f6585fe07"
};
