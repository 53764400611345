import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type PropsType = {
  text: string;
  children?: React.ReactNode;
};

export const MiddleTitle: React.FC<PropsType & BoxProps> = ({
  text,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      sx={{
        "@media screen and (max-width: 900px)": {
          "& p": { fontSize: 12 },
          "& p:nth-of-type(2), p:nth-of-type(3)": {
            ml: 3,
            "&:after": { left: "-16px" },
          },
        },
      }}
    >
      <Typography color="#cb0400" fontSize={13} fontWeight="bold">
        {t("Top_title")}
      </Typography>
      <Typography
        sx={{
          color: "#aaa",
          fontSize: 13,
          fontWeight: "bold",
          position: "relative",
          ml: 4,
          "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "50%",
            left: "-24px",
            marginTop: "-1px",
            width: "10px",
            height: "1px",
            background: "#000",
          },
        }}
      >
        {text}
      </Typography>
      {children}
    </Box>
  );
};
export default MiddleTitle;
