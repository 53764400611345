import React from "react";
import { Layout, Philosophy } from "src/components/common";
import { Box } from "@mui/material";

export const PhilosophyPage: React.FC = () => {
  return (
    <Layout>
      <Box sx={{ color: "#000" }}>
        <Philosophy />
      </Box>
    </Layout>
  );
};

export default PhilosophyPage;
