import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowFillDownIcon } from "src/components/icons";
import { useTranslation } from "react-i18next";

export type MenuType = { title: string; url: string; onClick?: string[] };

type PropsType = {
  isOpen: boolean;
  onChange: (value: boolean) => void;
};

export const Header: React.FC<PropsType> = ({ isOpen, onChange }) => {
  const router = useLocation();
  const navigate = useNavigate();
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const isHome = router.pathname === "/";
  const { t, i18n } = useTranslation();
  const menus: MenuType[] = [
    { title: t("header.home"), url: "/" },
    { title: t("header.performance"), url: "/service" },
    // { title: t("header.directions"), url: "/philosophy" },
    { title: t("header.careers"), url: "/recruit" },
    { title: t("header.about"), url: "/about" },
  ];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const offset =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (offset && offset >= 450) setIsScroll(true);
      else setIsScroll(false);
    });
  }, []);

  const handleChangeLanguage = (text: string) => {
    i18n.changeLanguage(text);
    localStorage.setItem("language", text);
  };

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          color: "#fff",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10,
          "&:after": {
            content: '""',
            width: isOpen
              ? "100%"
              : isHome
              ? isScroll
                ? "100%"
                : "0%"
              : "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            background: isOpen
              ? "#fff"
              : isHome
              ? isScroll
                ? "#fff"
                : "transparent"
              : "#fff",
            boxShadow: isOpen
              ? "0 0 14px 0 rgba(0, 0, 0, 0.1)"
              : isHome
              ? isScroll
                ? "0 0 14px 0 rgba(0, 0, 0, 0.1)"
                : ""
              : "0 0 14px 0 rgba(0, 0, 0, 0.1)",
            zIndex: -1,
            transition:
              "0.3s width cubic-bezier(0.23, 1, 0.32, 1), 0.3s background-color cubic-bezier(0.23, 1, 0.32, 1), 0.3s box-shadow cubic-bezier(0.23, 1, 0.32, 1)",
          },
        }}
      >
        <Box
          sx={{
            width: 214,
            minWidth: 214,
            height: 84,
            // backgroundColor: "#fff",
            cursor: "pointer",
            "& img": {
              width: "100%",
              height: "auto",
              objectFit: "cover",
              m: "26px 0",
            },
            "@media screen and (max-width: 900px)": {
              width: 171,
              minWidth: 171,
              height: 65,
              "& img": { m: "20px 0" },
            },
          }}
          onClick={() => navigate("/")}
        >
          <img
            src={require("src/assets/images/shulun_logo.png")}
            alt=""
            width={2000}
            height={2000}
          />
        </Box>
        <Stack
          flexDirection="row"
          height={84}
          pr="50px"
          sx={{ "@media screen and (max-width: 900px)": { display: "none" } }}
        >
          {menus.map((item) => (
            <Box
              key={item.title}
              onClick={() => {
                navigate(item.url);
              }}
              sx={{
                pl: "25px",
                "& a": {
                  whiteSpace: "nowrap",
                  padding: "31px 0 30.5px",
                  display: "block",
                  color: isHome
                    ? isScroll
                      ? "#000"
                      : "#fff"
                    : router.pathname === item.url
                    ? "#cb0400"
                    : "#000",
                  fontWeight: "bold",
                  textDecoration: "none",
                  transition: "0.3s width cubic-bezier(0.23, 1, 0.32, 1)",
                  position: "relative",
                  "&:hover": { color: "#cb0400" },
                  "&:hover:after": { width: "100%" },
                  "&:after": {
                    content: '""',
                    width: router.pathname === item.url ? "100%" : "0%",
                    height: "3px",
                    display: "block",
                    position: "absolute",
                    left: "50%",
                    bottom: 0,
                    transform: "translate(-50%, 0)",
                    transition: "0.3s width cubic-bezier(0.23, 1, 0.32, 1)",
                    background:
                      "linear-gradient(to right, #cb0400 50%, #cb0400 50%, #0f158c 50%)",
                    backgroundColor: "#cb0400",
                  },
                },
              }}
            >
              <a href={item.url}>{item.title}</a>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              ml: "40px",
              ".MuiBox-root": { display: "none" },
              "&:hover .MuiBox-root": { display: "block" },
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                p: "6px 12px",
                cursor: "pointer",
                border: isHome
                  ? isScroll
                    ? "2px solid #e5e5e5"
                    : "2px solid rgba(255, 255, 255, 0.2)"
                  : "2px solid #e5e5e5",
              }}
            >
              <Typography
                fontWeight="bold"
                fontSize={14}
                color={isHome ? (isScroll ? "#000" : "#fff") : "#000"}
                whiteSpace="nowrap"
              >
                {i18n.language === "zh"
                  ? t("header.chinese")
                  : i18n.language === "ja"
                  ? t("header.japanese")
                  : t("header.english")}
              </Typography>
              <ArrowFillDownIcon
                sx={{
                  width: 15,
                  height: 15,
                  "& path": {
                    fill: isHome ? (isScroll ? "#cb0400" : "#fff") : "#cb0400",
                  },
                }}
              />
            </Stack>
            <Box
              sx={{
                p: "15px 20px",
                border: "1px solid #fff",
                backgroundColor: "#fff",
                color: "#000",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
                position: "absolute",
                top: "75px",
                right: 0,
                "& p": {
                  fontSize: 14,
                  lineHeight: 1.8,
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: "#000",
                  display: "block",
                  whiteSpace: "nowrap",
                },
                "& p:first-of-type": {
                  color: i18n.language === "zh" ? "#cb0400" : "#000",
                },
                "& p:nth-of-type(2)": {
                  color: i18n.language === "ja" ? "#cb0400" : "#000",
                },
                "& p:last-of-type": {
                  color: i18n.language === "en" ? "#cb0400" : "#000",
                },
                "& p:hover": { color: "#cb0400" },
              }}
            >
              <Typography onClick={() => handleChangeLanguage("zh")}>
                {t("header.chinese")}
              </Typography>
              <Typography onClick={() => handleChangeLanguage("ja")}>
                {t("header.japanese")}
              </Typography>
              <Typography onClick={() => handleChangeLanguage("en")}>
                {t("header.english")}
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Box
          sx={{
            height: "12px",
            p: "16px 14px",
            backgroundColor: isHome
              ? isScroll
                ? "#fff"
                : "transparent"
              : "#fff",
            position: "absolute",
            top: 10,
            right: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.12)",
            transition:
              "0.2s background-color cubic-bezier(0.23, 1, 0.32, 1), 0.3s box-shadow cubic-bezier(0.23, 1, 0.32, 1)",
            "& span:first-of-type": {
              display: "block",
              width: 16,
              height: 2,
              // mb: 1,
              backgroundColor: isOpen
                ? "#cb0400"
                : isHome
                ? isScroll
                  ? "#cb0400"
                  : "#fff"
                : "#cb0400",
              mt: isOpen ? "2px" : "0",
              transition: "0.3s all cubic-bezier(0.23, 1, 0.32, 1)",
              transform: isOpen
                ? "translate(25%, 25%) rotate(-135deg) translate3d(0, 0, 0)"
                : "translate(0%, 0%) translate3d(0, 0, 0)",
              transformOrigin: isOpen ? "40% 150%" : "unset",
            },
            "& span:last-of-type": {
              display: "block",
              width: 16,
              height: 2,
              backgroundColor: isOpen
                ? "#0f158c"
                : isHome
                ? isScroll
                  ? "#0f158c"
                  : "#fff"
                : "#0f158c",
              mb: isOpen ? "3px" : "0",
              transition: "0.3s all cubic-bezier(0.23, 1, 0.32, 1)",
              transform: isOpen
                ? "translate(20%, 20%) rotate(135deg) translate3d(0, 0, 0)"
                : "translate(0%, 0%) translate3d(0, 0, 0)",
              transformOrigin: isOpen ? "40% -50%" : "unset",
            },
            cursor: "pointer",
            "@media screen and (min-width: 900px)": { display: "none" },
          }}
          onClick={() => onChange(!isOpen)}
        >
          <span></span>
          <span></span>
        </Box>
      </Stack>
    </>
  );
};

export default Header;
