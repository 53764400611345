import React, {ReactElement, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {
    BoardTitle,
    BossBSE,
    BossTech,
    BSE,
    MiddleTitle,
    Tech,
    uidNamePosition,
} from "../home";
import {Layout} from "../common";
import {
    getAuth,
    isSignInWithEmailLink,
    signInWithEmailLink,
    onAuthStateChanged,
    setPersistence,
    browserLocalPersistence
} from "firebase/auth";
import {Flex, Select} from "antd";
import {
    fetchDataFromDatabase,
    findLatestDoc,
    returnedDataType,
} from "../home/dataExchange";
import {ReviewDataType} from "@src/components/home/sheet1";
import {getSeasonNumber} from "../home/Time";
import {LogoutFunction} from "../home/logoutFunction";
import {MuiButton} from "../icons/MuiButtons";
import {EmployeesDialog} from "../home/EmployeesDialog";
import Button from "@mui/material/Button";
import {ProgressIcon} from "../icons/progressIcon";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

export interface dateType {
    startDate: string;
    endDate1: string;
    endDate2: string;
}

export const EmployeesPage: React.FC = () => {
    const [employeeName, setEmployeeName] = useState<string | null>("");
    const [employeeUid, setEmployeeUid] = useState<string | null>("");
    const [employeePosition, setEmployeePosition] = useState<string | null>("");
    const [reviewDataList, setReviewDataList] = useState<ReviewDataType[]>([{}]);
    const [interviewData, setInterviewData] = useState<number[]>();
    const [isSelectedPositionButtonChanged, setIsSelectedPositionButtonChanged] =
        useState<boolean>(false);
    const [sheet, setSheet] = useState<ReactElement<any, any> | null>(null);
    const [positionButton, setPositionButton] = useState<ReactElement<any, any> | undefined>(undefined);
    const [receivedDate, setReceivedDate] = useState<dateType>({
        startDate: "",
        endDate1: "",
        endDate2: "",
    });
    const [seasonName, setSeasonName] = useState<string>("");
    const [isOpenFlag, setIsOpenFlag] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

    useEffect(() => {
        const userAuthentication = async () => {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    console.log('用户已登录');
                    setEmployeeUid(user.uid);
                } else {
                    console.log('用户已注销');
                    let email = window.localStorage.getItem("emailForSignIn");
                    if (isSignInWithEmailLink(auth, window.location.href)) {
                        setPersistence(auth, browserLocalPersistence).then(() => {
                            window.localStorage.setItem("uploadInterviewPermission", "true");
                            if (email) return signInWithEmailLink(auth, email, window.location.href);
                        }).catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.log(errorCode, errorMessage);
                        });
                        if (email === null || email === "") {
                            email = "initEmail";
                            window.localStorage.removeItem("emailForSignIn");
                            alert("链接已失效，请返回首页重新输入邮箱地址");
                            LogoutFunction();
                            window.location.href = "/evaluation/login";
                        }
                        signInWithEmailLink(auth, email, window.location.href)
                            .then((result) => {
                                window.localStorage.removeItem('emailForSignIn');
                                setEmployeeUid(result.user.uid);
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                console.log(errorCode, errorMessage);
                            });
                    } else {
                        window.location.href = "/evaluation/login";
                    }
                }
            });
        }
        userAuthentication().then(() => {
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        const userCheck = async () => {
            const usersInformation = (await fetchDataFromDatabase(7, null, null, null)) as uidNamePosition[];
            if (employeeUid)setIsOpenFlag(isFirstLoginCheck(usersInformation, employeeUid));
        }
        userCheck().catch((error) => {
            console.log(error)
        })
    }, [employeeUid]);


    useEffect(() => {
        const receiveDate = async () => {
            const receive = await findLatestDoc();
            if (receive) {
                const saveReceiveData: dateType = {
                    startDate: receive.docData.startDate,
                    endDate1: receive.docData.endDate1,
                    endDate2: receive.docData.endDate2,
                };
                setReceivedDate(saveReceiveData);
                setSeasonName(receive.docId);
            }
        };

        receiveDate().catch((Error) => {
            console.log(Error);
        });

    }, [employeeUid]);

    const selectedRewriteButton = () => {
        if (
            getSeasonNumber(receivedDate) !== 1 ||
            !window.localStorage.getItem("isNotFirstTime")
        ) {
            return;
        } else {
            return (
                <MuiButton
                    isInProgress={false}
                    label={"重新填写数据"}
                    onClick={reWrite}
                    style={{marginTop: "10px"}}
                />
            );
        }
    };

    const selectedCheckButton = () => {
        if (window.localStorage.getItem("isNotFirstTime")) {
            return;
        } else {
            return (
                <MuiButton
                    isInProgress={false}
                    label={"查看数据"}
                    onClick={checkResult}
                    style={{marginTop: "10px"}}
                />
            );
        }
    };

    const isFirstLoginCheck = (
        usersInformation: uidNamePosition[],
        resultUid: string
    ) => {
        let needInputSymbol: boolean = true;
        for (let i = 0; i < usersInformation.length; i++) {
            if (usersInformation[i].uid === resultUid) {
                needInputSymbol = false;
                if (["ceo", "cto"].includes(usersInformation[i].position)) {
                    window.location.href = "/evaluation/back";
                } else {
                    setEmployeeName(usersInformation[i].name);
                    setEmployeePosition(usersInformation[i].position);
                }
            }
        }
        return needInputSymbol;
    };


    useEffect(() => {
        const fetchData = async () => {
            let reviewDataList: ReviewDataType[] = [];
            setInProgress(true);
            if (employeePosition === "techAndBse")
            {
                const TechSheetData: returnedDataType = await fetchDataFromDatabase(2, employeeUid, "tech", seasonName) as returnedDataType;
                const BseSheetData: returnedDataType = await fetchDataFromDatabase(2, employeeUid, "bse", seasonName) as returnedDataType;
                if (TechSheetData && BseSheetData){
                    reviewDataList.push(TechSheetData.reviewData?TechSheetData.reviewData:{},BseSheetData.reviewData?BseSheetData.reviewData:{});
                    setInterviewData(TechSheetData.interReviewData.score);
                }
            }else if(employeePosition === "tech"){
                const TechSheetData: returnedDataType = await fetchDataFromDatabase(2, employeeUid, "tech", seasonName) as returnedDataType;
                if (TechSheetData){
                    reviewDataList.push(TechSheetData.reviewData?TechSheetData.reviewData:{});
                    setInterviewData(TechSheetData.interReviewData.score);
                }
            }else if(employeePosition === "bse"){
                const BseSheetData: returnedDataType = await fetchDataFromDatabase(2, employeeUid, "bse", seasonName) as returnedDataType;
                if (BseSheetData){
                    reviewDataList.push({},BseSheetData.reviewData?BseSheetData.reviewData:{});
                    setInterviewData(BseSheetData.interReviewData.score);
                }
            }
            setInProgress(false);
            if (reviewDataList.length > 0) {
                setReviewDataList(reviewDataList);
            }
        };

        fetchData().catch((error) => {
            console.log(error);
        });
    }, [employeeUid, seasonName, employeePosition]);



    useEffect(() => {
        let selectSheet;
        if (inProgress) {
            selectSheet = (
                <div style={{marginTop: "40px"}}>
                    <ProgressIcon/>
                </div>
            )
        } else {
            if (
                window.localStorage.getItem("isNotFirstTime") &&
                employeePosition !== "bse" &&
                !window.localStorage.getItem("secondTime") &&
                !isSelectedPositionButtonChanged
            ) {
                selectSheet = (
                    <BossTech
                        downloadedData={reviewDataList[0]}
                        interReviewData={interviewData as number[]}
                        isFullscreen={isFullScreen}
                    />
                );
            } else if (
                !window.localStorage.getItem("isNotFirstTime") &&
                employeePosition !== "bse" &&
                !window.localStorage.getItem("secondTime") &&
                !isSelectedPositionButtonChanged
            ) {
                selectSheet = (
                    <Tech
                        employeeUid={employeeUid as string}
                        permission={1}
                        isGoToUploadSuccessPage={true}
                        isTechAndBse={employeePosition === "techAndBse"}
                        date={receivedDate}
                        seasonName={seasonName}
                        review={reviewDataList[0]}
                    />
                );
            } else if (
                (window.localStorage.getItem("isNotFirstTime") &&
                    employeePosition === "bse") ||
                isSelectedPositionButtonChanged
            ) {
                selectSheet = (
                    <BossBSE
                        downloadedData={reviewDataList[1]}
                        interReviewData={interviewData as number[]}
                        isFullscreen={isFullScreen}
                    />
                );
            } else {
                selectSheet = (
                    <BSE
                        employeeUid={employeeUid as string}
                        permission={1}
                        isGoToUploadSuccessPage={true}
                        seasonName={seasonName}
                        date={receivedDate}
                        review={reviewDataList[1]}
                    />
                );
            }
        }

        let selectedPositionButton;
        if (
            employeePosition === "techAndBse" &&
            window.localStorage.getItem("isNotFirstTime")
        ) {
            selectedPositionButton = (
                <Select
                    onSelect={(value) => {
                        if (value === "bse") {
                            setIsSelectedPositionButtonChanged(true);
                        } else {
                            setIsSelectedPositionButtonChanged(false);
                        }
                    }}
                    defaultValue="tech"
                    style={{width: 120, textAlign: "center"}}
                    allowClear
                    options={[
                        {value: "tech", label: "技术人员"},
                        {value: "bse", label: "BSE"},
                    ]}
                />
            );
        }
        setSheet(selectSheet);
        setPositionButton(selectedPositionButton);
    }, [employeePosition, reviewDataList, interviewData, isSelectedPositionButtonChanged, receivedDate, seasonName, employeeUid, setIsSelectedPositionButtonChanged, inProgress, isFullScreen]);

    const reWrite = () => {
        window.localStorage.removeItem("isNotFirstTime");
        window.location.href = "/evaluation/front";
    };
    const checkResult = () => {
        window.localStorage.setItem("isNotFirstTime", "true");
        window.location.href = "/evaluation/front";
    };
    const handleLogout = async () => {
        LogoutFunction();
        window.location.href = "/evaluation/login";
    };

    const screenControlIcon = () => {
        if (isFullScreen) {
            return <FullscreenExitIcon fontSize={'large'} onClick={() => {
                setIsFullScreen(false);
            }}
                                       style={{cursor: 'pointer'}}/>
        } else {
            return <FullscreenIcon fontSize={'large'} onClick={() => {
                setIsFullScreen(true);
            }}
                                   style={{cursor: 'pointer'}}/>
        }
    };

    return (
        <Layout>
            {isOpenFlag && <EmployeesDialog uid={employeeUid ? employeeUid : ""}/>}
            <Box
                sx={{
                    width: "100%%",
                    m: "0 auto",
                    p: "40px 0 0",
                    color: "#000",
                    "@media screen and (max-width: 900px)": {padding: "30px 0 0"},
                }}
            >
                <Box
                    maxWidth={1200}
                    m="0 auto 50px"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        "@media screen and (max-width: 900px)": {
                            mb: "30px",
                            "& p": {fontSize: 34},
                        },
                    }}
                >
                    <BoardTitle arrow="center" isLine>
                        <Typography
                            fontSize={50}
                            lineHeight={1.2}
                            letterSpacing={4}
                            fontWeight="bold"
                            textAlign="center"
                            whiteSpace="normal"
                        >
                            {"书论京成综合评测系统"}
                        </Typography>
                    </BoardTitle>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        height: 380,
                        background: `url(${require("../../assets/images/chengdu1.jpeg")}) no-repeat center center`,
                        backgroundSize: "cover",
                        backgroundPosition: "0% 80%",
                        opacity: 0.6,
                        "@media screen and (max-width: 400px)": {
                            height: 300,
                        },
                    }}
                />
            </Box>
            <Box
                sx={{
                    width: isFullScreen ? "100%" : "88%",
                    maxWidth: isFullScreen ? 3000 : 1200,
                    height: "auto",
                    background: "#fff",
                    margin: "0 auto -68px",
                    position: "relative",
                    top: -68,
                    zIndex: 9,
                    "@media screen and (max-width: 900px)": {
                        top: -38,
                        margin: "0 auto -38px",
                    },
                }}
            >
                <Box
                    sx={{
                        width: "85%",
                        p: "0 0 80px",
                        m: "0 auto",
                        "& >.MuiBox-root:first-of-type": {
                            height: 68,
                            "& p": {lineHeight: "68px"},
                        },
                        "& >.MuiBox-root:nth-of-type(2)": {m: "70px auto 30px"},
                        "& >.MuiBox-root:nth-of-type(4)": {m: "0px auto 30px"},
                        "& >.MuiBox-root:nth-of-type(6)": {m: "70px auto 30px"},
                        "@media screen and (max-width: 900px)": {
                            width: "100%",
                            "& >.MuiBox-root:first-of-type": {
                                width: "85%",
                                m: "0 auto",
                            },
                            "& >.MuiBox-root:nth-of-type(2)": {
                                m: "40px auto 0px",
                                "& p": {fontSize: 26},
                            },
                            "& >.MuiBox-root:nth-of-type(4)": {
                                m: "40px auto 0px",
                                "& p": {fontSize: 26},
                            },
                            "& >.MuiBox-root:nth-of-type(6)": {
                                m: "40px auto 0px",
                                "& p": {fontSize: 26},
                            },
                        },
                    }}
                >
                    <MiddleTitle text={"sheet"}/>
                    <Box style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            whiteSpace: 'nowrap'
                        }}>
                            <p>欢迎你：{employeeName}</p>
                            <div style={{marginLeft: "20px"}}>
                                <Button variant="outlined" onClick={handleLogout} size={"small"}>
                                    登出
                                </Button>
                            </div>
                        </div>
                        <div style={{marginLeft: 'auto'}}>
                            {screenControlIcon()}
                        </div>
                        <div style={{textAlign: "right"}}>{positionButton}</div>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <BoardTitle arrow="center">
                                <Typography
                                    fontSize={36}
                                    lineHeight={1.2}
                                    letterSpacing={4}
                                    fontWeight="bold"
                                    textAlign="center"
                                    whiteSpace="normal"
                                >
                                    {employeePosition === "bse" ||
                                    window.localStorage.getItem("secondTime") ||
                                    isSelectedPositionButtonChanged
                                        ? "BSE综合评测表"
                                        : "信息技术工程师综合评测表"}
                                </Typography>
                            </BoardTitle>
                        </Box>
                        <div style={{width: "100%"}}>
                            {sheet}
                        </div>
                        <div>
                            <Flex gap="small" wrap="wrap">
                                {selectedRewriteButton()}
                                {selectedCheckButton()}
                            </Flex>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
};

export default EmployeesPage;
