import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Footer, Header, MenuType } from ".";
import { FooterTop, ToTop } from "src/components/home";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type PropsType = {
  children?: React.ReactNode;
};

export const Layout: React.FC<PropsType> = ({ children }) => {
  const router = useLocation();
  const isHome = router.pathname === "/";
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const menus: MenuType[] = [
    { title: t("header.home"), url: "/" },
    { title: t("header.performance"), url: "/service" },
    { title: t("header.careers"), url: "/recruit" },
    { title: t("header.about"), url: "/about" },
  ];

  const handleChangeLanguage = (text: string) => {
    i18n.changeLanguage(text);
    localStorage.setItem("language", text);
    setIsOpen(false);
  };

  useEffect(() => {
    const titleElement = document.querySelector("title");
    const descriptionElement = document.querySelector(
      'meta[name="description"]'
    );
    const ogTitleElement = document.querySelector('meta[property="og:title"]');
    const ogDescriptionElement = document.querySelector(
      'meta[property="og:description"]'
    );
    if (titleElement) {
      titleElement.innerText = t("title");
    }
    if (descriptionElement) {
      descriptionElement.setAttribute("content", t("description"));
    }
    if (ogTitleElement) {
      ogTitleElement.setAttribute("content", t("title"));
    }
    if (ogDescriptionElement) {
      ogDescriptionElement.setAttribute("content", t("description"));
    }
  }, [t]);

  return (
    <Stack
      sx={{
        minHeight: "100vh",
        justifyContent: "space-between",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Header isOpen={isOpen} onChange={setIsOpen} />
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 65px)",
          backgroundColor: "#fff",
          position: "fixed",
          top: 65,
          left: 0,
          zIndex: 10,
          opacity: isOpen ? 1 : 0,
          visibility: isOpen ? "visible" : "hidden",
          borderTop: "1px solid #f4f3f3",
          transition:
            "0.3s opacity cubic-bezier(0.23, 1, 0.32, 1), 0.3s visibility cubic-bezier(0.23, 1, 0.32, 1)",
        }}
      >
        <Box>
          {menus.map((item, index) => (
            <Box
              key={index}
              onClick={() => navigate(item.url)}
              sx={{
                ml: "28%",
                padding: "25px 0 15px 30px",
                fontSize: 23,
                fontWeight: "bold",
                lineHeight: 1,
                position: "relative",
                zIndex: 2,
                cursor: "pointer",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: "35px",
                  left: "0",
                  marginTop: "-1px",
                  width: "15px",
                  height: "1px",
                  background: "#000",
                },
              }}
            >
              {item.title}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: "88%",
            height: "calc(100vh - 133px)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            m: "auto",
            "& span": {
              position: "absolute",
              top: 0,
              width: "1px",
              height: "100%",
              backgroundColor: "#f5f5f5",
            },
            zIndex: 1,
            "& span:first-of-type": { left: 0 },
            "& span:nth-of-type(2)": { left: "25%" },
            "& span:nth-of-type(3)": { left: "50%" },
            "& span:nth-of-type(4)": { right: "25%" },
            "& span:last-of-type": { right: 0 },
          }}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 67,
            position: "absolute",
            bottom: 0,
            backgroundColor: "#f4f3f3",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTop: "1px solid #f4f3f3",
            "& p": { cursor: "pointer" },
            "& p + p": { position: "relative", ml: "24px" },
            "& p + p:before": {
              content: '"/"',
              position: "absolute",
              left: -12,
            },
            "& .is_selected": {
              color: "#cb0400",
              position: "relative",
              "&:before": { color: "#000" },
              "&:after": {
                content: '""',
                display: "block",
                position: "absolute",
                right: 0,
                bottom: "-7px",
                left: "50%",
                margin: "auto",
                width: "100%",
                height: "2px",
                transform: "translate(-50%, 0)",
                background:
                  "linear-gradient(to right, #cb0400 50%, #cb0400 50%, #0f158c 50%)",
                backgroundColor: "#cb0400",
              },
            },
          }}
        >
          <Typography
            fontSize={16}
            fontWeight="bold"
            onClick={() => handleChangeLanguage("zh")}
            className={i18n.language === "zh" ? "is_selected" : ""}
          >
            {t("header.chinese")}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight="bold"
            onClick={() => handleChangeLanguage("ja")}
            className={i18n.language === "ja" ? "is_selected" : ""}
          >
            {t("header.japanese")}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight="bold"
            onClick={() => handleChangeLanguage("en")}
            className={i18n.language === "en" ? "is_selected" : ""}
          >
            {t("header.english")}
          </Typography>
        </Box>
      </Box>
      {isHome ? (
        children
      ) : (
        <Box
          sx={{
            pt: "84px",
            "@media screen and (max-width: 900px)": { pt: "65px" },
          }}
        >
          {children}
        </Box>
      )}
      <Box>
        <ToTop />
        <FooterTop />
      </Box>
      <Footer />
    </Stack>
  );
};
export default Layout;
