import React from "react";
import { Layout } from "src/components/common";
import { Box, Typography } from "@mui/material";
import { BoardTitle, MiddleTitle } from "src/components/home";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ProductDetail: React.FC = () => {
  const router = useLocation();
  const { t, i18n } = useTranslation();
  const array = [
    {
      id: "1",
      text: t("products.list.tag_1.title"),
      link: "",
      content: [
        {
          title: t("products.list.tag_1.content.title"),
          children: [
            t("products.list.tag_1.content.text_1"),
            t("products.list.tag_1.content.text_2"),
            t("products.list.tag_1.content.text_3"),
            t("products.list.tag_1.content.text_4"),
            t("products.list.tag_1.content.text_5"),
            t("products.list.tag_1.content.text_6"),
          ],
          summary: [],
        },
      ],
      projects: [
        {
          title: t("products.list.tag_1.detail.project_1.title"),
          summary: [t("products.list.tag_1.detail.project_1.text_1")],
          function: [
            t("products.list.tag_1.detail.project_1.text_2"),
            t("products.list.tag_1.detail.project_1.text_3"),
            t("products.list.tag_1.detail.project_1.text_4"),
          ],
          technology: [
            t("products.list.tag_1.detail.project_1.text_5"),
            t("products.list.tag_1.detail.project_1.text_6"),
            t("products.list.tag_1.detail.project_1.text_7"),
          ],
        },
        {
          title: t("products.list.tag_1.detail.project_2.title"),
          summary: [t("products.list.tag_1.detail.project_2.text_1")],
          function: [
            t("products.list.tag_1.detail.project_2.text_2"),
            t("products.list.tag_1.detail.project_2.text_3"),
            t("products.list.tag_1.detail.project_2.text_4"),
          ],
          technology: [
            t("products.list.tag_1.detail.project_2.text_5"),
            t("products.list.tag_1.detail.project_2.text_6"),
            t("products.list.tag_1.detail.project_2.text_7"),
          ],
        },
        {
          title: t("products.list.tag_1.detail.project_3.title"),
          summary: [t("products.list.tag_1.detail.project_3.text_1")],
          function: [
            t("products.list.tag_1.detail.project_3.text_2"),
            t("products.list.tag_1.detail.project_3.text_3"),
          ],
          technology: [
            t("products.list.tag_1.detail.project_3.text_5"),
            t("products.list.tag_1.detail.project_3.text_6"),
            t("products.list.tag_1.detail.project_3.text_7"),
          ],
        },
        {
          title: t("products.list.tag_1.detail.project_4.title"),
          summary: [t("products.list.tag_1.detail.project_4.text_1")],
          function: [
            t("products.list.tag_1.detail.project_4.text_2"),
            t("products.list.tag_1.detail.project_4.text_3"),
          ],
          technology: [
            t("products.list.tag_1.detail.project_4.text_5"),
            t("products.list.tag_1.detail.project_4.text_6"),
            t("products.list.tag_1.detail.project_4.text_7"),
          ],
        },
        {
          title: t("products.list.tag_1.detail.project_5.title"),
          summary: [t("products.list.tag_1.detail.project_5.text_1")],
          function: [
            t("products.list.tag_1.detail.project_5.text_2"),
            t("products.list.tag_1.detail.project_5.text_3"),
            t("products.list.tag_1.detail.project_5.text_4"),
            t("products.list.tag_1.detail.project_5.text_5"),
          ],
          technology: [
            t("products.list.tag_1.detail.project_5.text_6"),
            t("products.list.tag_1.detail.project_5.text_7"),
            t("products.list.tag_1.detail.project_5.text_8"),
          ],
        },
        {
          title: t("products.list.tag_1.detail.project_6.title"),
          summary: [t("products.list.tag_1.detail.project_6.text_1")],
          function: [
            t("products.list.tag_1.detail.project_6.text_2"),
            t("products.list.tag_1.detail.project_6.text_3"),
            t("products.list.tag_1.detail.project_6.text_4"),
          ],
          technology: [
            t("products.list.tag_1.detail.project_6.text_5"),
            t("products.list.tag_1.detail.project_6.text_6"),
            t("products.list.tag_1.detail.project_6.text_7"),
          ],
        },
        {
          title: t("products.list.tag_1.detail.project_7.title"),
          summary: [t("products.list.tag_1.detail.project_7.text_1")],
          function: [
            t("products.list.tag_1.detail.project_7.text_2"),
            t("products.list.tag_1.detail.project_7.text_3"),
          ],
          technology: [
            t("products.list.tag_1.detail.project_7.text_5"),
            t("products.list.tag_1.detail.project_7.text_6"),
            t("products.list.tag_1.detail.project_7.text_7"),
          ],
        },
        {
          title: t("products.list.tag_1.detail.project_8.title"),
          summary: [t("products.list.tag_1.detail.project_8.text_1")],
          function: [
            t("products.list.tag_1.detail.project_8.text_2"),
            t("products.list.tag_1.detail.project_8.text_3"),
          ],
          technology: [t("products.list.tag_1.detail.project_8.text_4")],
        },
      ],
      lang: "ja",
    },
    {
      id: "2",
      text: t("products.list.tag_2.title"),
      link: "",
      content: [
        {
          title: t("products.list.tag_2.content.title_1"),
          children: [
            t("products.list.tag_2.content.text_1"),
            t("products.list.tag_2.content.text_2"),
            t("products.list.tag_2.content.text_3"),
            t("products.list.tag_2.content.text_4"),
            t("products.list.tag_2.content.text_5"),
            t("products.list.tag_2.content.text_6"),
          ],
          summary: [],
        },
      ],
      projects: [
        {
          title: t("products.list.tag_2.detail.project_1.title"),
          summary: [t("products.list.tag_2.detail.project_1.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_2.detail.project_2.title"),
          summary: [t("products.list.tag_2.detail.project_2.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_2.detail.project_3.title"),
          summary: [t("products.list.tag_2.detail.project_3.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_2.detail.project_4.title"),
          summary: [t("products.list.tag_2.detail.project_4.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_2.detail.project_5.title"),
          summary: [t("products.list.tag_2.detail.project_5.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_2.detail.project_6.title"),
          summary: [t("products.list.tag_2.detail.project_6.text_1")],
          function: [],
          technology: [],
        },
      ],
      lang: "ja",
    },
    {
      id: "3",
      text: t("products.list.tag_3.title"),
      link: "",
      content: [
        {
          title: t("products.list.tag_3.content.title_1"),
          children: [],
          summary: [
            t("products.list.tag_3.content.summary_1"),
            t("products.list.tag_3.content.summary_2"),
          ],
        },
        {
          title: t("products.list.tag_3.content.title_2"),
          children: [],
          summary: [
            t("products.list.tag_3.content.summary_3"),
            t("products.list.tag_3.content.summary_4"),
          ],
        },
      ],
      projects: [
        {
          title: t("products.list.tag_3.detail.project_1.title"),
          summary: [t("products.list.tag_3.detail.project_1.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_3.detail.project_2.title"),
          summary: [t("products.list.tag_3.detail.project_2.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_3.detail.project_3.title"),
          summary: [t("products.list.tag_3.detail.project_3.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_3.detail.project_4.title"),
          summary: [t("products.list.tag_3.detail.project_4.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_3.detail.project_5.title"),
          summary: [t("products.list.tag_3.detail.project_5.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_3.detail.project_6.title"),
          summary: [t("products.list.tag_3.detail.project_6.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_3.detail.project_7.title"),
          summary: [t("products.list.tag_3.detail.project_7.text_1")],
          function: [],
          technology: [],
        },
        {
          title: t("products.list.tag_3.detail.project_8.title"),
          summary: [t("products.list.tag_3.detail.project_8.text_1")],
          function: [],
          technology: [],
        },
      ],
      lang: "ja",
    },
  ];
  const queryParams = new URLSearchParams(router.search);
  const item = array.filter(
    (item) => item.id === (queryParams.get("id") as string)
  )[0];

  return (
    <Layout>
      <Box
        sx={{
          color: "#000",
          p: "50px 0 0",
          backgroundColor: "#fff",
          "@media screen and (max-width: 900px)": { padding: "30px 0 0" },
        }}
      >
        <Box
          maxWidth={1200}
          m="0 auto 50px"
          sx={{
            display: "flex",
            justifyContent: "center",
            "@media screen and (max-width: 900px)": {
              mb: "30px",
              "& p": { fontSize: 34 },
            },
          }}
        >
          <BoardTitle arrow="center" isLine>
            <Typography
              fontSize={50}
              lineHeight={1.2}
              letterSpacing={4}
              fontWeight="bold"
              textAlign="center"
              whiteSpace="normal"
            >
              {t("header.performance")}
            </Typography>
          </BoardTitle>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 380,
            background: `url(${require("src/assets/images/chengdu1.jpeg")}) no-repeat center center`,
            backgroundSize: "cover",
            backgroundPosition: "0% 80%",
            opacity: 0.6,
            "@media screen and (max-width: 400px)": {
              height: 300,
            },
          }}
        />
      </Box>
      <Box sx={{ backgroundColor: "#fff", pt: "68px", pb: "68px" }}>
        <Box
          sx={{
            width: "88%",
            maxWidth: 1200,
            height: 68,
            background: "#fff",
            margin: "0 auto -68px",
            position: "relative",
            top: -136,
            zIndex: 9,
            "& >.MuiBox-root": {
              width: "85%",
              height: "100%",
              m: "0 auto",
              "& p": { lineHeight: "68px" },
            },
            "@media screen and (max-width: 900px)": { top: -110 },
          }}
        >
          <MiddleTitle text={t("header.performance")}>
            <Typography
              sx={{
                color: "#aaa",
                fontSize: 13,
                fontWeight: "bold",
                position: "relative",
                ml: 5,
                "&:after": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: "50%",
                  left: "-24px",
                  marginTop: "-1px",
                  width: "10px",
                  height: "1px",
                  background: "#000",
                },
                "@media screen and (max-width: 900px)": {
                  display: i18n.language === "en" ? "none" : "block",
                },
              }}
            >
              {item?.text}
            </Typography>
          </MiddleTitle>
        </Box>
      </Box>
      <Box
        sx={{
          width: "88%",
          maxWidth: 1200,
          height: "auto",
          background: "#fff",
          margin: "0 auto -68px",
          position: "relative",
          top: -68,
          zIndex: 9,
        }}
      >
        <Box
          sx={{
            width: "85%",
            p: "0 0 80px",
            m: "0 auto",
            "@media screen and (max-width: 900px)": {
              width: "100%",
              padding: "0px 0 80px",
            },
          }}
        >
          <Box
            sx={{
              pt: 5,
              "& .MuiBox-root:first-of-type": { mb: "70px" },
              "& .ant-carousel, .slick-slider": { height: 600 },
              "@media screen and (max-width: 900px)": {
                pt: 0,
                "& >.MuiBox-root:first-of-type": {
                  mb: 4,
                  "& p": { fontSize: 26 },
                },
                "& .ant-carousel, .slick-slider": { height: "50vw" },
              },
            }}
          >
            <Box
              sx={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                "& .MuiBox-root": { mb: "0px !important" },
              }}
            >
              <BoardTitle arrow="center" isLine>
                <Typography
                  fontSize={32}
                  lineHeight={1.2}
                  letterSpacing={4}
                  fontWeight="bold"
                  textAlign="center"
                  whiteSpace="normal"
                >
                  {router.search === "?id=3"
                    ? item.text.split(" ")[0]
                    : item?.text}
                </Typography>
              </BoardTitle>
            </Box>
            <Box
              color="#000"
              sx={{
                "& >dl": {
                  display: "flex",
                  height: "auto",
                  m: "0",
                  fontSize: 14,
                },
                "& dt": {
                  width: "25%",
                  p: "18px 30px",
                  borderTop: "1px solid #f5f5f5",
                  borderLeft: "1px solid #f5f5f5",
                  borderRight: "1px solid #f5f5f5",
                  position: "relative",
                  fontWeight: "bold",
                  lineHeight: 2.5,
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    top: "-1px",
                    left: "0",
                    width: "6px",
                    height: "1px",
                    background: "#000",
                  },
                },
                "& dl:last-of-type ": {
                  "& dt:after": {
                    content: '""',
                    position: "absolute",
                    bottom: "-1px",
                    left: "0",
                    width: "6px",
                    height: "1px",
                    background: "#000",
                  },
                  "& dt, dd": { borderBottom: "1px solid #f5f5f5 !important" },
                },
                "& dl:nth-of-type(2)": {
                  "& dt:after": {
                    content: '""',
                    position: "absolute",
                    bottom: "-1px",
                    left: "0",
                    width: router.search === "?id=3" ? "6px" : "0px",
                    height: "1px",
                    background: "#000",
                  },
                  "& dt, dd": {
                    borderBottom:
                      router.search === "?id=3" ? "1px solid #f5f5f5" : "none",
                  },
                },
                "& dd": {
                  width: "75%",
                  p: "18px 30px",
                  ml: 0,
                  borderTop: "1px solid #f5f5f5",
                  borderRight: "1px solid #f5f5f5",
                  " >dl": { m: "0px !important" },
                },
                "@media screen and (max-width: 900px)": {
                  "& dt": { width: "20%", padding: "12px 10px" },
                  "& dd": { width: "80%", padding: "12px 10px" },
                },
              }}
            >
              {(queryParams.get("id") as string) === array[0].id ? (
                item.projects.map((i, index) => (
                  <dl>
                    <dt>{i.title}</dt>
                    <dd>
                      <Typography
                        fontSize={14}
                        fontWeight="bold"
                        lineHeight={2.5}
                      >
                        {t("products.list.detail_summary")}
                      </Typography>
                      {i.summary.map((j) => (
                        <Typography key={j} fontSize={13} lineHeight={1.8}>
                          {j}
                        </Typography>
                      ))}
                      <Typography
                        fontSize={14}
                        fontWeight="bold"
                        lineHeight={2.5}
                      >
                        {t("products.list.detail_function")}
                      </Typography>
                      {i.function.map((j) => (
                        <Typography key={j} fontSize={13} lineHeight={1.8}>
                          {j}
                        </Typography>
                      ))}
                      <Typography
                        fontSize={14}
                        fontWeight="bold"
                        lineHeight={2.5}
                      >
                        {t("products.list.detail_technology")}
                      </Typography>
                      {i.technology.map((j) => (
                        <Typography key={j} fontSize={13} lineHeight={1.8}>
                          {j}
                        </Typography>
                      ))}
                    </dd>
                  </dl>
                ))
              ) : (
                <>
                  {router.search === "?id=2" && (
                    <>
                      <dl>
                        <dt>{item.text}</dt>
                        <dd>
                          <Typography fontSize={13} lineHeight={1.5}>
                            {item.content[0].title}
                          </Typography>
                        </dd>
                      </dl>
                      <dl>
                        <dt>{t("performance.detail.detail_overview")}</dt>
                        <dd>
                          <dl>
                            {item.projects.map((i, index) => (
                              <>
                                <Typography
                                  fontSize={14}
                                  fontWeight="bold"
                                  lineHeight={2.5}
                                >
                                  {i.title}
                                </Typography>
                                <Typography
                                  fontSize={13}
                                  lineHeight={1.5}
                                  sx={{ textIndent: "2em" }}
                                >
                                  {i.summary}
                                </Typography>
                              </>
                            ))}
                          </dl>
                        </dd>
                      </dl>
                    </>
                  )}
                  {router.search === "?id=3" && (
                    <>
                      <Typography
                        fontSize="18px !important"
                        lineHeight={2.5}
                        fontWeight="bold"
                        sx={{
                          textIndent: "0",
                          textAlign: "center",
                          mb: 3,
                          "@media screen and (max-width: 900px)": {
                            fontSize: "16px !important",
                            lineHeight: 1.8,
                          },
                        }}
                      >
                        {item.content[0].title}
                      </Typography>
                      <dl>
                        <dt>{t("products.list.tag_3.min_title1")}</dt>
                        <dd>
                          {item.projects.slice(0, 5).map((i, index) => (
                            <Box m="0px !important" key={index}>
                              <Typography
                                fontSize="14px !important"
                                fontWeight="bold"
                                lineHeight={2.5}
                              >
                                {i.title}
                              </Typography>
                              <Typography
                                fontSize="13px !important"
                                lineHeight={1.5}
                                sx={{ textIndent: "2em" }}
                              >
                                {i.summary}
                              </Typography>
                            </Box>
                          ))}
                        </dd>
                      </dl>
                      <dl>
                        <dt></dt>
                        <dd>
                          <dl>
                            {!!item.content[0].summary.length && (
                              <Box m="0px !important">
                                {item.content[0].summary.map(
                                  (child, childIndex) => (
                                    <Typography
                                      key={childIndex}
                                      fontSize="14px !important"
                                      lineHeight={2}
                                      sx={{ textIndent: "2em" }}
                                    >
                                      {child}
                                    </Typography>
                                  )
                                )}
                              </Box>
                            )}
                          </dl>
                        </dd>
                      </dl>
                      <Box
                        sx={{
                          width: "auto",
                          display: "flex",
                          justifyContent: "center",
                          mt: "70px",
                          mb: "50px !important",
                          "& .MuiBox-root": { mb: "0px !important" },
                          "@media screen and (max-width: 900px)": {
                            mt: "50px",
                            mb: "32px !important",
                          },
                        }}
                      >
                        <BoardTitle arrow="center" isLine>
                          <Typography
                            fontSize={32}
                            lineHeight={1.2}
                            letterSpacing={4}
                            fontWeight="bold"
                            textAlign="center"
                            whiteSpace="normal"
                          >
                            {item?.text.split(" ")[1]}
                          </Typography>
                        </BoardTitle>
                      </Box>
                      <Typography
                        fontSize="18px !important"
                        lineHeight={2.5}
                        fontWeight="bold"
                        sx={{
                          textIndent: "0",
                          textAlign: "center",
                          mt: 3,
                          mb: 3,
                          "@media screen and (max-width: 900px)": {
                            fontSize: "16px !important",
                            lineHeight: 1.8,
                          },
                        }}
                      >
                        {item.content[1].title}
                      </Typography>
                      <dl>
                        <dt>{t("products.list.tag_3.min_title2")}</dt>
                        <dd>
                          {item.projects.slice(5, 8).map((i, index) => (
                            <Box m="0px !important" key={index}>
                              <Typography
                                fontSize="14px !important"
                                fontWeight="bold"
                                lineHeight={2.5}
                              >
                                {i.title}
                              </Typography>
                              <Typography
                                fontSize="13px !important"
                                lineHeight={1.5}
                                sx={{ textIndent: "2em" }}
                              >
                                {i.summary}
                              </Typography>
                            </Box>
                          ))}
                        </dd>
                      </dl>
                      <dl>
                        <dt></dt>
                        <dd>
                          <dl>
                            {!!item.content[1].summary.length && (
                              <Box m="0px !important">
                                {item.content[1].summary.map(
                                  (child, childIndex) => (
                                    <Typography
                                      key={childIndex}
                                      fontSize="14px !important"
                                      lineHeight={2}
                                      sx={{ textIndent: "2em" }}
                                    >
                                      {child}
                                    </Typography>
                                  )
                                )}
                              </Box>
                            )}
                          </dl>
                        </dd>
                      </dl>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ProductDetail;
