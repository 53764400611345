import React from "react";
import { Box } from "@mui/material";
import { ArrowFillDownIcon } from "src/components/icons";
import { useLocation } from "react-router-dom";

export const ToTop: React.FC = () => {
  const location = useLocation();

  return (
    <Box
      sx={{
        width: "88%",
        position: "absolute",
        right: 0,
        left: 0,
        textAlign: "center",
        zIndex: 2,
        m: "0 auto",
        "& a": {
          width: "100%",
          display: "block",
          maxWidth: 1200,
          height: 68,
          m: "0 auto",
          position: "relative",
          backgroundColor: "#fff",
          zIndex: 1,
          "&:after": {
            content: '""',
            width: "100%",
            height: "0%",
            position: "absolute",
            bottom: 0,
            left: 0,
            transition: "0.3s height cubic-bezier(0.23, 1, 0.32, 1)",
            backgroundColor: "#cb0400",
          },
          "& svg": {
            width: 25,
            height: 25,
            mt: "19px",
            transform: "rotate(-180deg)",
            "& path": { fill: "#cb0400" },
          },
          "&:hover svg path": { fill: "#fff" },
          "&:hover:after": { height: "100%", zIndex: -1 },
          "@media screen and (max-width: 900px)": {
            height: 38,
            "& svg": { width: 20, height: 20, mt: 1 },
          },
        },
      }}
    >
      <a href={location.pathname + location.search}>
        <ArrowFillDownIcon />
      </a>
    </Box>
  );
};
export default ToTop;
