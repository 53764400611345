import React from "react";
import { Layout } from "src/components/common";
import { Box, Typography } from "@mui/material";
import { BoardTitle, MiddleTitle } from "src/components/home";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const JoinDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const array = [
    {
      id: "1",
      name: t("careers.Technology.position_1.title"),
      duties: [
        t("careers.Technology.position_1.description.text_1"),
        t("careers.Technology.position_1.description.text_2"),
        t("careers.Technology.position_1.description.text_3"),
      ],
      requirement: [
        t("careers.Technology.position_1.requirements.text_1"),
        t("careers.Technology.position_1.requirements.text_2"),
        t("careers.Technology.position_1.requirements.text_3"),
        t("careers.Technology.position_1.requirements.text_4"),
        t("careers.Technology.position_1.requirements.text_5"),
        t("careers.Technology.position_1.requirements.text_6"),
        t("careers.Technology.position_1.requirements.text_7"),
        t("careers.Technology.position_1.requirements.text_8"),
      ],
      advantage: [
        t("careers.Technology.position_1.advantages.text_1"),
        t("careers.Technology.position_1.advantages.text_2"),
        t("careers.Technology.position_1.advantages.text_3"),
      ],
      type: "technology",
    },
    {
      id: "2",
      name: t("careers.Technology.position_2.title"),
      duties: [
        t("careers.Technology.position_2.description.text_1"),
        t("careers.Technology.position_2.description.text_2"),
        t("careers.Technology.position_2.description.text_3"),
      ],
      requirement: [
        t("careers.Technology.position_2.requirements.text_1"),
        t("careers.Technology.position_2.requirements.text_2"),
        t("careers.Technology.position_2.requirements.text_3"),
        t("careers.Technology.position_2.requirements.text_4"),
        t("careers.Technology.position_2.requirements.text_5"),
        t("careers.Technology.position_2.requirements.text_6"),
        t("careers.Technology.position_2.requirements.text_7"),
      ],
      advantage: [
        t("careers.Technology.position_2.advantages.text_1"),
        t("careers.Technology.position_2.advantages.text_2"),
        t("careers.Technology.position_2.advantages.text_3"),
      ],
      type: "technology",
    },
    {
      id: "3",
      name: t("careers.Technology.position_3.title"),
      duties: [
        t("careers.Technology.position_3.description.text_1"),
        t("careers.Technology.position_3.description.text_2"),
        t("careers.Technology.position_3.description.text_3"),
      ],
      requirement: [
        t("careers.Technology.position_3.requirements.text_1"),
        t("careers.Technology.position_3.requirements.text_2"),
        t("careers.Technology.position_3.requirements.text_3"),
        t("careers.Technology.position_3.requirements.text_4"),
        t("careers.Technology.position_3.requirements.text_5"),
        t("careers.Technology.position_3.requirements.text_6"),
      ],
      advantage: [
        t("careers.Technology.position_3.advantages.text_1"),
        t("careers.Technology.position_3.advantages.text_2"),
        t("careers.Technology.position_3.advantages.text_3"),
      ],
      type: "technology",
    },
    {
      id: "4",
      name: t("careers.Technology.position_4.title"),
      duties: [
        t("careers.Technology.position_4.description.text_1"),
        t("careers.Technology.position_4.description.text_2"),
        t("careers.Technology.position_4.description.text_3"),
      ],
      requirement: [
        t("careers.Technology.position_4.requirements.text_1"),
        t("careers.Technology.position_4.requirements.text_2"),
        t("careers.Technology.position_4.requirements.text_3"),
        t("careers.Technology.position_4.requirements.text_4"),
        t("careers.Technology.position_4.requirements.text_5"),
        t("careers.Technology.position_4.requirements.text_6"),
      ],
      advantage: [
        t("careers.Technology.position_4.advantages.text_1"),
        t("careers.Technology.position_4.advantages.text_2"),
        t("careers.Technology.position_4.advantages.text_3"),
        t("careers.Technology.position_4.advantages.text_4"),
      ],
      type: "technology",
    },
    {
      id: "5",
      name: t("careers.internship.position_1.title"),
      duties: [
        t("careers.internship.position_1.description.text_1"),
        t("careers.internship.position_1.description.text_2"),
        t("careers.internship.position_1.description.text_3"),
        t("careers.internship.position_1.description.text_4"),
      ],
      requirement: [
        t("careers.internship.position_1.requirements.text_1"),
        t("careers.internship.position_1.requirements.text_2"),
        t("careers.internship.position_1.requirements.text_3"),
        t("careers.internship.position_1.requirements.text_4"),
        t("careers.internship.position_1.requirements.text_5"),
        t("careers.internship.position_1.requirements.text_6"),
      ],
      advantage: [
        t("careers.internship.position_1.advantages.text_1"),
        t("careers.internship.position_1.advantages.text_2"),
        t("careers.internship.position_1.advantages.text_3"),
        t("careers.internship.position_1.advantages.text_4"),
      ],
      type: "internship",
    },
    {
      id: "6",
      name: t("careers.internship.position_2.title"),
      duties: [
        t("careers.internship.position_2.description.text_1"),
        t("careers.internship.position_2.description.text_2"),
        t("careers.internship.position_2.description.text_3"),
        t("careers.internship.position_2.description.text_4"),
      ],
      requirement: [
        t("careers.internship.position_2.requirements.text_1"),
        t("careers.internship.position_2.requirements.text_2"),
        t("careers.internship.position_2.requirements.text_3"),
        t("careers.internship.position_2.requirements.text_4"),
        t("careers.internship.position_2.requirements.text_5"),
      ],
      advantage: [
        t("careers.internship.position_2.advantages.text_1"),
        t("careers.internship.position_2.advantages.text_2"),
        t("careers.internship.position_2.advantages.text_3"),
        t("careers.internship.position_2.advantages.text_4"),
      ],
      type: "internship",
    },
    {
      id: "7",
      name: t("careers.language.position_1.title"),
      duties: [
        t("careers.language.position_1.description.text_1"),
        t("careers.language.position_1.description.text_2"),
        t("careers.language.position_1.description.text_3"),
      ],
      requirement: [
        t("careers.language.position_1.requirements.text_1"),
        t("careers.language.position_1.requirements.text_2"),
        t("careers.language.position_1.requirements.text_3"),
        t("careers.language.position_1.requirements.text_4"),
        t("careers.language.position_1.requirements.text_5"),
      ],
      advantage: [
        t("careers.language.position_1.advantages.text_1"),
        t("careers.language.position_1.advantages.text_2"),
      ],
      type: "language",
    },
    {
      id: "8",
      name: t("careers.language.position_2.title"),
      duties: [
        t("careers.language.position_2.description.text_1"),
        t("careers.language.position_2.description.text_2"),
        t("careers.language.position_2.description.text_3"),
        t("careers.language.position_2.description.text_4"),
      ],
      requirement: [
        t("careers.language.position_2.requirements.text_1"),
        t("careers.language.position_2.requirements.text_2"),
        t("careers.language.position_2.requirements.text_3"),
        t("careers.language.position_2.requirements.text_4"),
      ],
      advantage: [
        t("careers.language.position_2.advantages.text_1"),
        t("careers.language.position_2.advantages.text_2"),
        t("careers.language.position_2.advantages.text_3"),
      ],
      type: "language",
    },
  ];
  const router = useLocation();
  const queryParams = new URLSearchParams(router.search);
  const item = array.filter(
    (item) => item.id === (queryParams.get("id") as string)
  )[0];

  return (
    <Layout>
      <Box
        sx={{
          width: "100%%",
          m: "0 auto",
          p: "40px 0 0",
          color: "#000",
          "@media screen and (max-width: 900px)": { padding: "30px 0 0" },
        }}
      >
        <Box
          maxWidth={1200}
          m="0 auto 50px"
          sx={{
            display: "flex",
            justifyContent: "center",
            "@media screen and (max-width: 900px)": {
              mb: "30px",
              "& p": { fontSize: 34 },
            },
          }}
        >
          <BoardTitle arrow="center" isLine>
            <Typography
              fontSize={50}
              lineHeight={1.2}
              letterSpacing={4}
              fontWeight="bold"
              textAlign="center"
              whiteSpace="normal"
            >
              {t("careers.title")}
            </Typography>
          </BoardTitle>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 380,
            background: `url(${require("src/assets/images/chengdu1.jpeg")}) no-repeat center center`,
            backgroundSize: "cover",
            backgroundPosition: "0% 80%",
            opacity: 0.6,
            "@media screen and (max-width: 400px)": {
              height: 300,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: "88%",
          maxWidth: 1200,
          height: "auto",
          background: "#fff",
          margin: "0 auto -68px",
          position: "relative",
          top: -68,
          zIndex: 9,
          "@media screen and (max-width: 900px)": { top: -38 },
        }}
      >
        <Box
          sx={{
            width: "85%",
            p: "0 0 80px",
            m: "0 auto",
            "& >.MuiBox-root:first-of-type": {
              height: 68,
              "& p": { lineHeight: "68px" },
            },
            "& >.MuiBox-root:nth-of-type(2)": { m: "70px 0 30px" },
            "& >.MuiBox-root:nth-of-type(4)": { m: "70px 0 30px" },
            "@media screen and (max-width: 900px)": {
              width: "100%",
              "& >.MuiBox-root:first-of-type": { width: "85%", m: "0 auto" },
              "& >.MuiBox-root:nth-of-type(2)": {
                m: "40px 0 0px",
                " & p": { fontSize: 26 },
              },
              "& >.MuiBox-root:nth-of-type(4)": {
                m: "40px 0 0px",
                " & p": { fontSize: 26 },
              },
            },
          }}
        >
          <MiddleTitle text={t("careers.title")}>
            <Typography
              sx={{
                color: "#aaa",
                fontSize: 13,
                fontWeight: "bold",
                position: "relative",
                ml: 5,
                "&:after": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: "50%",
                  left: "-24px",
                  marginTop: "-1px",
                  width: "10px",
                  height: "1px",
                  background: "#000",
                },
                "@media screen and (max-width: 900px)": { display: "none" },
              }}
            >
              {item?.name}
            </Typography>
          </MiddleTitle>
          <Box
            sx={{
              width: "auto",
              display: "flex",
              justifyContent: "center",
              "@media screen and (max-width: 900px)": {
                "& p": { fontSize: "20px !important" },
              },
            }}
          >
            <BoardTitle arrow="center" isLine>
              <Typography
                fontSize={32}
                lineHeight={1.2}
                letterSpacing={4}
                fontWeight="bold"
                textAlign="center"
                whiteSpace="normal"
              >
                {item.name}
              </Typography>
            </BoardTitle>
          </Box>
          <Box
            pt={5}
            color="#000"
            sx={{
              padding: "40px 13%",
              "@media screen and (max-width: 900px)": { padding: "40px 0%" },
            }}
          >
            <Box>
              <Typography fontWeight="bold" lineHeight={2.2}>
                {t("careers.Technology.position_1.description.title")}：
              </Typography>
              {item.duties.map((i, index) => (
                <Typography key={index} fontSize={14} lineHeight={2}>
                  {i}
                </Typography>
              ))}
            </Box>
            <Box mt={2}>
              <Typography fontWeight="bold" lineHeight={2.2}>
                {t("careers.Technology.position_1.requirements.title")}：
              </Typography>
              {item.requirement.map((i, index) => (
                <Typography key={index} fontSize={14} lineHeight={2}>
                  {i}
                </Typography>
              ))}
            </Box>
            <Box mt={2}>
              <Typography fontWeight="bold" lineHeight={2.2}>
                {t("careers.Technology.position_1.advantages.title")}：
              </Typography>
              {item.advantage.map((i, index) => (
                <Typography key={index} fontSize={14} lineHeight={2}>
                  {i}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default JoinDetailPage;
