export const tecSheet: string[] = [
    `工作总量：实际承担工作量（以客户标准计算的人月数、难度等）。\n进度管理：在管理多个任务和截止日期方面的效率。\n紧急应对：在应对突发紧急任务时的主动性和应急成果。\n问题解决：在识别潜在问题和主动提出解决方案的能力。\n额外贡献：参与超出日常职责范围的工作（额外的工作、协助同事）。\n抗压能力：在压力或紧急情况下的工作表现和情绪管理。`,
    `错误缺陷：代码中的错误、缺陷、错误重复出现频率。\n代码优化：能很好地将同事或上级的反馈应用于代码优化中。\n最佳实践：积极推广最佳实践和新技术。\n持续改进：持续改进现有流程方面的建议或贡献。`,
    `技术拓展：在拓展新技术栈方面达成实际成果。\n成长速度：快速学习成长、适应新项目、新工作内容。\n技术分享：在内部分享会上的参与和演讲。`,
    `协调沟通：在项目中的领导（技术）、协调、沟通效率。\n团队参与：对团队成果和整体成功的影响。\n团队贡献：在团队和公司战略规划中的参与和贡献。\n带领新人：对新人进行技术指导、工作安排并帮助其承担正式工作。`,
    `综合评价`,
];

export const bseSheet: string[] = [
    `进度管理：BSE在确保项目进度按时进行方面的能力。\n质量管理：BSE确保项目符合内部和外部质量标准的能力。\n风险预测：BSE在预测、反馈项目可能存在潜在风险方面的表现。`,
    `沟通技巧：BSE与客户、团队成员的沟通效果，包括及时性和准确性等。\n需求管理：BSE在理解和转述客户需求给技术团队方面的能力。\n团队沟通：BSE在促进团队良好关系、氛围方面的贡献。\n客户反馈：BSE的工作在客户那里得到的反馈。\n客情维护：BSE在维护和促进长期客户关系方面的贡献。`,
    `技术知识：BSE在理解项目所需技术和流程方面的深度和广度。\n问题解决：BSE在识别和协助解决技术或项目中问题的能力。\n持续改进：BSE在建议、实施流程改进方案方面的贡献。\n日语学习：BSE学习日语的积极性以及帮助他人学习日语的积极性。`,
    `综合评价`,
];
export const category: string[] = [
    "工作表现",
    "工作质量",
    "个人成长",
    "团队责任",
    "互评结果",
];

export const category2: string[] = [
    "管理协调",
    "沟通能力",
    "技术支持",
    "互评结果",
];
