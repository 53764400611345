import React from "react";
import { Box, Typography } from "@mui/material";

export type MessageItemType = {
  time: string;
  tag: string;
  text: string;
  url: string;
};

type PropsType = {
  item: MessageItemType;
  isOdd: boolean;
  isTag?: boolean;
  isOmit?: boolean;
};

export const MessageItem: React.FC<PropsType> = ({
  item,
  isOdd = true,
  isTag = true,
  isOmit = false,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        fontSize: 16,
        color: "#cb0400",
        position: "relative",
        zIndex: 1,
        backgroundColor: isOdd ? "#fafafa" : "#fff",
        "&:hover": {
          zIndex: 2,
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
          color: "#cb0400",
        },
        "& .MuiBox-root": {
          display: "flex",
          textDecoration: "none",
          padding: "18px 35px",
          zIndex: 1,
          "@media screen and (max-width: 900px)": {
            display: "block",
            padding: "15px 20px 18px",
          },
        },
        "& .MuiBox-root:hover": {
          zIndex: 2,
          "& p": { color: "#cb0400" },
          "& span:nth-of-type(2)": { backgroundColor: "#cb0400" },
        },
      }}
    >
      <Box>
        <Typography
          color="#7d7d7d"
          pr="25px"
          whiteSpace="nowrap"
          display="inline-flex"
        >
          <Typography
            component="span"
            fontSize={12}
            fontWeight="bold"
            minWidth={85}
            display="inline-block"
          >
            {item.time}
          </Typography>
          {isTag && (
            <Typography
              component="span"
              sx={{
                height: 18,
                lineHeight: "18px",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: 0,
                ml: "20px",
                p: "2px 4px",
                fontSize: 13,
                whiteSpace: "nowrap",
                "@media screen and (max-width: 900px)": { fontSize: 12 },
              }}
            >
              {item.tag}
            </Typography>
          )}
        </Typography>

        <Typography
          color="#000"
          fontSize={13}
          sx={{
            overflow: isOmit ? "hidden" : "unset",
            textOverflow: isOmit ? "ellipsis" : "unset",
            whiteSpace: isOmit ? "nowrap" : "unset",
            "@media screen and (max-width: 900px)": {
              mt: 0.5,
              fontSize: "12px !important",
            },
          }}
        >
          {item.text}
        </Typography>
      </Box>
    </Box>
  );
};
export default MessageItem;
