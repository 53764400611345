import React from "react";
import { Box, Typography } from "@mui/material";
import { Layout } from "src/components/common";
import { BoardTitle, MessageItem, MiddleTitle } from "src/components/home";
import { useTranslation } from "react-i18next";

export const History: React.FC = () => {
  const { t } = useTranslation();
  const messages = [
    {
      time: "2012.01.05",
      tag: t("home.history.tag"),
      text: t("home.history.list.option1"),
      url: "",
    },
    {
      time: "2014.03.03",
      tag: t("home.history.tag"),
      text: t("home.history.list.option2"),
      url: "",
    },
    {
      time: "2019.09.01",
      tag: t("home.history.tag"),
      text: t("home.history.list.option3"),
      url: "",
    },
    {
      time: "2021.10.01",
      tag: t("home.history.tag"),
      text: t("home.history.list.option4"),
      url: "",
    },
    {
      time: "2022.01.01",
      tag: t("home.history.tag"),
      text: t("home.history.list.option5"),
      url: "",
    },
    {
      time: "2023.06.01",
      tag: t("home.history.tag"),
      text: t("home.history.list.option6"),
      url: "",
    },
  ];

  return (
    <Layout>
      <Box
        sx={{
          width: "100%",
          m: "0 auto",
          p: "40px 0 80px",
          color: "#000",
          backgroundColor: "#f5f5f5",
          "@media screen and (max-width: 900px)": { padding: "30px 0 70px" },
        }}
      >
        <Box
          width="88%"
          maxWidth={1200}
          m="0 auto 50px"
          sx={{
            "@media screen and (max-width: 900px)": {
              mb: "30px",
              "& p": { fontSize: 34 },
            },
          }}
        >
          <BoardTitle arrow="center" width="100%">
            <Typography
              fontSize={50}
              lineHeight={1.2}
              letterSpacing={4}
              fontWeight="bold"
              textAlign="center"
              whiteSpace="normal"
            >
              {t("home.history.top_title")}
            </Typography>
          </BoardTitle>
        </Box>
      </Box>
      <Box
        sx={{
          width: "88%",
          maxWidth: 1200,
          height: "auto",
          background: "#fff",
          margin: "0 auto -68px",
          position: "relative",
          top: -68,
          zIndex: 9,
          "@media screen and (max-width: 900px)": {
            top: -38,
            margin: "0 auto -38px",
          },
        }}
      >
        <Box
          sx={{
            width: "85%",
            p: "0 0 80px",
            m: "0 auto",
            "& >.MuiBox-root:first-of-type": {
              height: 68,
              "& p": { lineHeight: "68px" },
            },
            "& >.MuiBox-root:nth-of-type(2)": { m: "70px 0 30px" },
            "& >.MuiBox-root:nth-of-type(4)": { m: "70px 0 30px" },
            "@media screen and (max-width: 900px)": {
              width: "100%",
              "& >.MuiBox-root:first-of-type": {
                width: "85%",
                m: "0 auto",
              },
              "& >.MuiBox-root:nth-of-type(2)": {
                m: "40px 0 0px",
                "& p": { fontSize: 26 },
              },
              "& >.MuiBox-root:nth-of-type(4)": {
                m: "40px 0 0px",
                "& p": { fontSize: 26 },
              },
            },
          }}
        >
          <MiddleTitle text={t("home.history.top_title")} />
          <Box
            width="100%"
            mb="50px"
            sx={{
              "@media screen and (max-width: 900px)": { width: "100%" },
            }}
          >
            {messages.map((item, index) => (
              <MessageItem
                key={index}
                item={item}
                isOdd={index % 2 === 0 ? true : false}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default History;
