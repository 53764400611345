import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ArrowFillDownIcon } from "src/components/icons";
import { MenuType } from "src/components/common";
import { useTranslation } from "react-i18next";

export const FooterTop: React.FC = () => {
  const { t } = useTranslation();
  const menus: MenuType[] = [
    { title: t("header.home"), url: "/" },
    { title: t("header.performance"), url: "/service" },
    { title: t("header.careers"), url: "/recruit" },
    { title: t("header.about"), url: "/about" },
    { title: t("footer.contact"), url: "mailto:contact@shuluntc.com" },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 0,
        backgroundColor: "#f5f5f5",
        pb: "25px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          background: `url(${require("src/assets/images/chengdu1.jpeg")}) no-repeat bottom`,
          backgroundSize: "cover",
          opacity: 0.6,
        }}
      ></Box>
      <Box
        sx={{
          p: "133px 0 28px",
          "@media screen and (max-width: 900px)": { p: "80px 0 28px" },
        }}
      >
        <Box
          sx={{
            width: "192px",
            height: 120,
            m: "auto",
            "& img": {
              width: "auto",
              height: 120,
              objectFit: "cover",
              position: "relative",
            },
            "@media screen and (max-width: 900px)": {
              height: 90,
              width: "100% !important",
              "& img": { height: "90px" },
            },
          }}
        >
          <img
            src={require("src/assets/images/shulun_logo2.png")}
            alt=""
            width={2000}
            height={2000}
          />
        </Box>
      </Box>
      <Stack
        flexDirection="row"
        height={35}
        justifyContent="center"
        sx={{
          "@media screen and (max-width: 900px)": {
            flexDirection: "column",
            width: "100%",
            height: "auto",
            "& .MuiBox-root:last-of-type": {
              borderBottom: "rgba(0, 0, 0, .3) solid 1px",
            },
          },
        }}
      >
        {menus.map((item, index) => (
          <Box
            key={item.title}
            sx={{
              pl: index === 0 ? 0 : "25px",
              "& a": {
                whiteSpace: "nowrap",
                pb: "10px",
                display: "block",
                color: "#000",
                fontWeight: "bold",
                textDecoration: "none",
                transition: "0.3s width cubic-bezier(0.23, 1, 0.32, 1)",
                position: "relative",
                "&:hover": { color: "#cb0400" },
                "&:hover:after": { width: "100%" },
                "&:after": {
                  content: '""',
                  width: "0%",
                  height: "3px",
                  display: "block",
                  position: "absolute",
                  left: "50%",
                  bottom: 0,
                  transform: "translate(-50%, 0)",
                  transition: "0.3s width cubic-bezier(0.23, 1, 0.32, 1)",
                  background:
                    "linear-gradient(to right, #cb0400 50%, #cb0400 50%, #0f158c 50%)",
                  backgroundColor: "#cb0400",
                },
                "& svg": { display: "none" },
              },
              "@media screen and (max-width: 900px)": {
                pl: 0,
                borderTop: "rgba(0, 0, 0, .3) solid 1px",
                "& a": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "15px 5%",
                  "&:hover": { color: "#cb0400" },
                  "&:hover:after": { width: "0%" },
                  "& svg": {
                    width: 15,
                    height: 15,
                    display: "block",
                    transform: "rotate(-90deg)",
                    "& path": { fill: "#cb0400" },
                  },
                },
              },
            }}
          >
            <a
              href={item.url}
              target={index === menus.length - 1 ? "_blank" : "_self"}
              rel="noreferrer"
            >
              {item.title}
              <ArrowFillDownIcon />
            </a>
          </Box>
        ))}
      </Stack>
      <Typography fontSize={12} mt="30px" color="#000" position="relative">
        {t("footer_top")}
      </Typography>
    </Box>
  );
};
export default FooterTop;
