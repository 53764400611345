import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BoardTitle, MoreButton } from ".";
import { useTranslation } from "react-i18next";

export const Introduction: React.FC = () => {
  const { t, i18n } = useTranslation();
  const divRef = useRef<HTMLDivElement | null>(null);
  const [divToTopHeight, setDivToTopHeight] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const handleScroll = useCallback(() => {
    setScrollHeight(window.scrollY);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      const rect = divRef.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight(distanceFromTop);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box
      ref={divRef}
      sx={{
        width: "calc(100% - 436px)",
        height: "auto",
        maxWidth: "1200px",
        m: "30px auto 120px",
        padding: "100px 218px",
        position: "relative",
        backgroundColor: "#ececec",
        "@media screen and (max-width: 900px)": {
          width: "calc(100% - 60px)",
          padding: "70px 30px",
          m: "30px auto 30px",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          opacity: ".7",
          background: `url(${require("src/assets/images/tokyo2.jpeg")}) no-repeat center`,
          backgroundSize: "cover",
        }}
      />
      <Typography
        sx={{
          width: "100%",
          fontSize: "14rem",
          overflow: "hidden",
          wordWrap: "normal",
          fontWeight: "bold",
          lineHeight: 1,
          letterSpacing: "-3px",
          color: "rgba(255, 255, 255, 0.95)",
          position: "absolute",
          left: 0,
          top: "-55px",
          textAlign: "center",
          zIndex: 2,
          opacity: ".9",
          "@media screen and (max-width: 900px)": {
            fontSize: "5.4rem",
            top: -30,
          },
        }}
      >
        {t("home.introduction.bg_title")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          background: "rgba(255, 255, 255, 0.7)",
          color: "#000",
          textAlign: "center",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          p="86px 16.5%"
          sx={{
            "@media screen and (max-width: 900px)": {
              padding: "40px 20px",
            },
          }}
        >
          <BoardTitle
            arrow="center"
            isLine={
              window.innerHeight + scrollHeight > divToTopHeight + 180
                ? true
                : false
            }
          >
            <Typography
              fontSize={50}
              lineHeight={1.2}
              letterSpacing={4}
              fontWeight="bold"
            >
              {t("home.introduction.top_title")}
            </Typography>
            <Typography fontSize={18} fontWeight="bold" mt="20px">
              {t("home.introduction.bottom_title")}
            </Typography>
          </BoardTitle>
          <Typography m="38px auto 24px" fontSize={14} lineHeight={2}>
            {t("home.introduction.content.text_1")}
            <br />
            {t("home.introduction.content.text_2")}
            {i18n.language === "zh" && (
              <>
                <br />
                {t("home.introduction.content.text_3")}
                <br />
                {t("home.introduction.content.text_4")}
              </>
            )}
          </Typography>
          <MoreButton
            text={t("home.introduction.button_title")}
            link="/service"
            isArrow
          />
        </Box>
      </Box>
    </Box>
  );
};
export default Introduction;
