import React, {useEffect, useState} from "react";
import {Table, Flex, Space, Input, Select, notification} from "antd";
import type {TableColumnsType} from "antd";
import {bseSheet, category2} from "../common";
import {uploadReviewData} from "./dataExchange";
import {selectNumber} from "./sheet1";
import type {DataType, ReviewDataType, remarkType} from "./sheet1"
import {dateType} from "@src/components/pages/EmployeesPage";
import {MuiButton} from "../icons/MuiButtons";
import {getSeasonNumber} from "../home/Time";
import runes from "runes2";

export interface InputSheetProps {
    employeeUid: string;
    permission: number;
    isGoToUploadSuccessPage: boolean;
    isTechAndBse?: boolean;
    date?: dateType;
    seasonName: string;
    review: ReviewDataType;
    isBossPage?: boolean;
}

const {TextArea} = Input;
const categoryNumber: number = 3;
const selectCheck = (data: ReviewDataType | undefined, reviewFromDatabaseList: string[]) => {
    const filteredList = reviewFromDatabaseList.filter(item => item !== undefined);
    if (data) {
        const categories = Object.keys(data);
        return categories.length === categoryNumber || categoryNumber === filteredList.length;
    } else {
        return categoryNumber === filteredList.length;
    }
};
const filterOption = (
    input: string,
    option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const BSE: React.FC<InputSheetProps> = ({
                                                   employeeUid,
                                                   permission,
                                                   isGoToUploadSuccessPage,
                                                   seasonName,
                                                   date,
                                                   review,
                                                   isBossPage
                                               }) => {
    const [myData, setMyData] = useState<ReviewDataType>();
    const [api, contextHolder] = notification.useNotification();
    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const remarkNameList: string[] = [
        "reviewFromEmployees",
        "reviewFromCeo",
        "reviewFromCto",
    ];
    const [selfValue, setSelfValue] = useState<string[]>([]);
    const [ceoValue, setCeoValue] = useState<string[]>([]);
    const [ctoValue, setCtoValue] = useState<string[]>([]);
    const [remarkValue, setRemarkValue] = useState<remarkType[]>([]);
    const collectionProgress = getSeasonNumber(
        date
            ? date
            : {
                startDate: "",
                endDate1: "",
                endDate2: "",
            }
    );

    const selectUploadButton = () => {
        if (collectionProgress !== 1 && permission === 1) {
            return;
        } else {
            return (
                <Flex gap="small" wrap="wrap">
                    <MuiButton
                        isInProgress={isInProgress}
                        label={"上传数据"}
                        onClick={handleSaveAndRedirect2}
                    />
                </Flex>
            );
        }
    };
    useEffect(() => {
        let selfList: string[] = [];
        let ceoList: string[] = [];
        let ctoList: string[] = [];
        let remarkList: remarkType[] = [];

        if (review) {
            for (let i = 0; i < categoryNumber; i++) {
                const dynamicKey: string = `category${i + 1}`;
                if (review[dynamicKey]) {
                    let remark: remarkType = {};
                    selfList.push(review[dynamicKey].self);
                    ceoList.push(review[dynamicKey].ceo);
                    ctoList.push(review[dynamicKey].cto);
                    if (review[dynamicKey].reviewFromEmployees) remark.reviewFromEmployees = review[dynamicKey].reviewFromEmployees;
                    if (review[dynamicKey].reviewFromCeo) remark.reviewFromCeo = review[dynamicKey].reviewFromCeo;
                    if (review[dynamicKey].reviewFromCto) remark.reviewFromCto = review[dynamicKey].reviewFromCto;
                    remarkList.push(remark);
                }
            }
        }

        setSelfValue(selfList);
        setCeoValue(ceoList);
        setCtoValue(ctoList);
        setRemarkValue(remarkList);
    }, [review]);

    const saveToHook = (key1: React.Key, key2: string, value: string) => {
        setMyData((prevData) => {
            const updatedData: ReviewDataType = {...prevData};

            const numKey1 = parseInt(key1 as string, 10);
            const categoryKey = `category${numKey1 + 1}` as keyof typeof updatedData;

            if (value !== "" || ["reviewFromEmployees","reviewFromCeo","reviewFromCto"].includes(key2)) {
                const newData = {[key2]: value};
                updatedData[categoryKey] = {...updatedData[categoryKey], ...newData};
            }
            return updatedData;
        });
    };

    const openNotification = () => {
        api.open({
            message: "上传成功",
            description: "上传数据成功",
            duration: 3,
        });
    };

    const handleSaveAndRedirect2 = () => {
        setIsInProgress(true);
        if (selectCheck(myData, permission === 1 ? selfValue : permission === 2 ? ceoValue : ctoValue)) {
            if (myData) {
                console.log("MY2",myData);
                uploadReviewData(myData, employeeUid, "BSE", seasonName).then(() => {
                    openNotification();
                    setIsInProgress(false);
                    if (isGoToUploadSuccessPage) {
                        window.location.href = "/evaluation/uploadSuccess";
                    }
                });
            } else {
                openNotification();
                setIsInProgress(false);
                if (isGoToUploadSuccessPage) {
                    window.location.href = "/evaluation/uploadSuccess";
                }
            }
        } else {
            setIsInProgress(false);
            alert("您有未填写的项目");
        }
    };

    const columns: TableColumnsType<DataType> = [
        {
            title: "自我评价",
            children: [
                {
                    title: "编号",
                    dataIndex: "no",
                    key: "no",
                    width: "5%",
                    align: "center",
                    render: (text) => (
                        <div style={{textAlign: "center"}}>
                            <strong>{text}</strong>
                        </div>
                    ),
                },
                {
                    title: "BSE评估",
                    children: [
                        {
                            title: "类别",
                            dataIndex: "category",
                            key: "category",
                            width: isGoToUploadSuccessPage?"5%":"7%",
                            align: "center",
                            render: (text) => (
                                <div style={{textAlign: "center"}}>
                                    <strong>{text}</strong>
                                </div>
                            ),
                        },
                        {
                            title: "评价项目",
                            dataIndex: "items",
                            key: "items",
                            align: "center",
                            width: isBossPage?"33%":"39%",
                            render: (text: string) => {
                                const sections: string[] = text.split(/([：。])/); // 保留冒号和句号并分割文本
                                return (
                                    <div
                                        style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: isGoToUploadSuccessPage?"12px":"14px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {sections.map((section: string, index: number) => {
                                            if (index % 4 < 2) {
                                                return <strong key={index}>{section}</strong>;
                                            } else {
                                                return section;
                                            }
                                        })}
                                    </div>
                                );
                            },
                        },
                    ],
                },
                {
                    title: "自我评价",
                    dataIndex: "self",
                    key: "self",
                    align: "center",
                    width: isBossPage?"6%":"8%",
                    render: (_, record, index) => (
                        permission === 1 && collectionProgress === 1 ? (
                            <Space>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        saveToHook(record.key, "self", value)
                                        const updatedSelfValue = [...selfValue];
                                        updatedSelfValue[index] = value;
                                        setSelfValue(updatedSelfValue);
                                    }}
                                    filterOption={filterOption}
                                    options={selectNumber}
                                    value={selfValue[index]}
                                    style={{width: 60}}
                                    disabled={permission !== 1 || collectionProgress !== 1}
                                />
                            </Space>
                        ) : (
                            <div style={{fontSize:"large"}}>
                                {selfValue[index]}
                            </div>
                        )
                    ),
                },
            ],
        },
        {
            title: "Boss复评",
            children: [
                {
                    title: "CEO评价",
                    dataIndex: "ceo",
                    key: "ceo",
                    width: isBossPage?"6%":"8%",
                    align: "center",
                    render: (_, record, index) => (
                        permission === 2 ? (
                            <Space>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        saveToHook(record.key, "ceo", value);
                                        const updatedCeoValue = [...ceoValue];
                                        updatedCeoValue[index] = value;
                                        setCeoValue(updatedCeoValue);
                                    }}
                                    value={ceoValue[index]}
                                    filterOption={filterOption}
                                    options={selectNumber}
                                    style={{width: 60}}
                                    disabled={permission !== 2}
                                />
                            </Space>) : (
                            <div style={{fontSize:"large"}}>
                                {ceoValue[index]}
                            </div>
                        )
                    ),
                },
                {
                    title: "CTO评价",
                    dataIndex: "cto",
                    key: "cto",
                    width: isBossPage?"6%":"8%",
                    align: "center",
                    render: (_, record, index) => (
                        permission === 3 ? (
                            <Space>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        saveToHook(record.key, "cto", value);
                                        const updatedCtoValue = [...ctoValue];
                                        updatedCtoValue[index] = value;
                                        setCtoValue(updatedCtoValue);
                                    }}
                                    value={ctoValue[index]}
                                    filterOption={filterOption}
                                    options={selectNumber}
                                    style={{width: 60}}
                                    disabled={permission !== 3}
                                />
                            </Space>) : (
                            <div style={{fontSize:"large"}}>
                                {ctoValue[index]}
                            </div>
                        )
                    ),
                },
            ],
        },
        {
            title: "文字评价",
            dataIndex: "exc",
            key: "exc",
            width: isBossPage?"41%":"35%",
            align: "center",
            render: (_, record, index) => (
                <div>
                    <div style={{fontSize:"x-small"}}>
                            {permission !== 1 && <div style={{
                                textAlign: "left",
                            }}>{`员工留言：${remarkValue[index]?.reviewFromEmployees || "无"}`}</div>}
                            {permission !== 2 && <div style={{
                                textAlign: "left",
                            }}>{`CEO留言：${remarkValue[index]?.reviewFromCeo || "无"}`}</div>}
                            {permission !== 3 && <div style={{
                                textAlign: "left",
                            }}>{`CTO留言：${remarkValue[index]?.reviewFromCto || "无"}`}</div>}
                    </div>
                    <div style={{marginBottom:"10px"}}>
                        <TextArea
                            rows={4}
                            maxLength={300}
                            placeholder = {"我的留言"}
                            value={
                                permission === 1 ? (remarkValue[index]?.reviewFromEmployees || '') :
                                    permission === 2 ? (remarkValue[index]?.reviewFromCeo || '') :
                                        (remarkValue[index]?.reviewFromCto || '')
                            }
                            count={{
                                show: true,
                                max: 300,
                                strategy: (txt: string) => txt.length,
                                exceedFormatter: (txt, {max}) => runes(txt).slice(0, max).join(''),
                            }}
                            onBlur={(e) =>
                                saveToHook(
                                    record.key,
                                    remarkNameList[permission - 1],
                                    e.target.value
                                )
                            }
                            onChange={(e) => {
                                const newValue = e.target.value;
                                setRemarkValue(prevRemarkValue => {
                                    const updatedRemarkValue = {...prevRemarkValue[index]}; // Create a shallow copy of the existing remarkValue object
                                    if (permission === 1) {
                                        updatedRemarkValue.reviewFromEmployees = newValue;
                                    } else if (permission === 2) {
                                        updatedRemarkValue.reviewFromCeo = newValue;
                                    } else {
                                        updatedRemarkValue.reviewFromCto = newValue;
                                    }
                                    const newRemarkValue = [...prevRemarkValue]; // Create a shallow copy of the previous remarkValue array
                                    newRemarkValue[index] = updatedRemarkValue; // Update the specific index with the updatedRemarkValue
                                    return newRemarkValue; // Return the updated remarkValue array
                                });
                            }}
                            disabled={collectionProgress !== 1 && permission === 1}
                        />
                    </div>
                </div>
            ),
        },
    ];
    const data: DataType[] = [];

    for (let i = 0; i < categoryNumber; i++) {
        data.push({
            key: i,
            no: i + 1,
            items: bseSheet[i],
            category: category2[i],
        });
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                size="middle"
            />
            <div style={{alignContent:"center",justifyContent:"center",alignItems:"center",display:"flex"}}>
                {contextHolder}
                {selectUploadButton()}
            </div>
        </>
    );
};
