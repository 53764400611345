import React from "react";
import { Box } from "@mui/material";
import { Banner, Layout } from "src/components/common";
import { History, Introduction, Philosophy, News } from "src/components/home";

export const HomePage: React.FC = () => {
  return (
    <Layout>
      <Banner />
      <Box>
        <Box
          sx={{
            maxWidth: "1200px",
            width: "88%",
            height: "auto",
            m: "0 auto -68px",
            position: "relative",
            top: "-68px",
            zIndex: 9,
            backgroundColor: "#fff",
            "@media screen and (max-width: 900px)": { top: "-38px" },
          }}
        >
          <History />
          <News />
        </Box>
        <Box
          sx={{
            maxWidth: "1200px",
            width: "88%",
            height: "auto",
            m: "80px auto -68px",
            position: "relative",
            top: "-68px",
            zIndex: 9,
            backgroundColor: "#fff",
            "@media screen and (max-width: 900px)": { width: "100%" },
          }}
        >
          <Introduction />
          <Philosophy />
        </Box>
      </Box>
    </Layout>
  );
};

export default HomePage;
