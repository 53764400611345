import React, {useEffect, useRef, useState} from "react";
import {UserOutlined} from "@ant-design/icons";
import type {MenuProps} from "antd";
import {
    Layout,
    Menu,
    theme,
    Select,
    Space,
    ConfigProvider,
    Flex,
    Progress,
    Tooltip,
} from "antd";
import {
    BSE,
    Tech,
    BossTech,
    BossBSE,
    fetchDataFromDatabase,
    findLatestDoc,
    getDate,
    getDateDifferencePercent,
    getDateDifference,
    LogoutFunction,
} from "../home";
import type {
    uidNamePosition,
    returnedDataType,
    ReviewDataType,
} from "../home";
import {PieChart} from "@mui/x-charts";
import {Stack, Container} from "@mui/material";
import {AccountPopover} from "../icons/SvgIcon";
import {EmptyIcon} from "../icons/EmptyIcon";
import imageSrc from "../../assets/images/shulun_logo.png";
import {BasicCard} from "../icons/BossPageCard";
import {ProgressIcon} from "../icons/progressIcon";
import {getAuth, onAuthStateChanged} from "firebase/auth";

const {Header, Content, Sider} = Layout;
const items1: MenuProps["items"] = ["后台评价系统", "查看评价表单"].map(
    (key) => ({
        key,
        label: `${key}`,
    })
);

interface valueAndLabel {
    value: string;
    label: string;
}

export const BossPage: React.FC = () => {
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();
    const [employeesUid, setEmployeesUid] = useState("");
    const [employeesPosition, setEmployeesPosition] = useState("");
    const [items2, setItems2] = useState<MenuProps["items"]>([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>("");
    const [title, setTitle] = useState("后台评价系统");
    const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
    const [review, setReview] = useState<
        ReviewDataType | uidNamePosition[] | undefined | string[]
    >();
    const [interReview, setInterReview] = useState<number[]>([]);
    const [selectedComponent, setSelectedComponent] =
        useState<React.ReactNode | null>(null);
    const [employeesQuantity, setEmployeesQuantity] = useState<number>();
    const [season, setSeason] = useState<string>("2024.Q1");
    const [isCto, setIsCto] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate1, setEndDate1] = useState<string>("");
    const [endDate2, setEndDate2] = useState<string>("");
    const [receivedSeasonData, setReceivedSeasonData] = useState<valueAndLabel[]>(
        []
    );
    const [inProgress, setInProgress] = useState<boolean>(false);
    const initData: ReviewDataType = {};
    const initData2: number[] = [];
    getAuth();
    useEffect(() => {
        const downloadDefaultSeasonValue = async () => {
            const SeasonDataList = (await fetchDataFromDatabase(
                6,
                null,
                null,
                null
            )) as string[];
            setSeason(SeasonDataList[SeasonDataList.length - 1]);
        };
        downloadDefaultSeasonValue().catch((Error) => {
            console.log(Error);
        });
    }, []);
    const handleMenuClick = async (
        uid: string,
        key: string,
        nowSeason?: string
    ) => {
        setInProgress(true);
        setSelectedMenuItem(key);
        const downloadedData = (await fetchDataFromDatabase(
            2,
            uid,
            key,
            nowSeason ? nowSeason : season
        )) as returnedDataType;
        if (downloadedData) {
            setInProgress(false);
            setReview(downloadedData.reviewData as ReviewDataType);
            setInterReview(downloadedData.interReviewData.score);
        } else {
            setReview(initData);
            setInterReview(initData2);
        }
    };

    useEffect(() => {
        let component = <EmptyIcon/>;
        if (inProgress) {
            component = employeesUid === "" ? <EmptyIcon/> : <ProgressIcon/>;
        } else {
            if (title === "查看评价表单") {
                const Component = selectedMenuItem === "tech" ? BossTech : BossBSE;
                component = (
                    <Component
                        downloadedData={review as ReviewDataType}
                        interReviewData={interReview}
                        isBossPage={true}
                    />
                );
            } else if (title === "后台评价系统") {
                const permission: number = isCto ? 3 : 2;
                const Component =
                    selectedMenuItem === "tech" ? Tech : selectedMenuItem === "bse" ? BSE : EmptyIcon;
                component = (
                    <Component
                        employeeUid={employeesUid}
                        permission={permission}
                        isGoToUploadSuccessPage={false}
                        seasonName={season}
                        review={review as ReviewDataType}
                        isBossPage={true}
                    />
                );
            }
        }
        setSelectedComponent(component);
    }, [
        isCto,
        review,
        employeesUid,
        interReview,
        selectedMenuItem,
        title,
        season,
        inProgress,
    ]);


    const selectedProgress = () => {
        if (getDateDifferencePercent(getDate(), startDate, endDate2) >= 100) {
            return (
                <Flex gap="small" wrap="wrap" style={{marginLeft: "30px"}}>
                    <Progress type="circle" percent={100} format={() => "Done"}/>
                </Flex>
            );
        } else {
            return (
                <Flex gap="small" wrap="wrap" style={{marginLeft: "30px"}}>
                    <Tooltip title="进度一览">
                        <Progress
                            percent={getDateDifferencePercent(getDate(), startDate, endDate2)}
                            success={{
                                percent: getDateDifferencePercent(
                                    endDate1,
                                    startDate,
                                    endDate2
                                ),
                            }}
                            type="circle"
                            format={() => `${getDateDifference(startDate, getDate())} Days`}
                        />
                    </Tooltip>
                </Flex>
            );
        }
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth,(user) =>{
            if (user){
                const fetchData = async () => {
                    try {
                        const userDataList = await fetchDataFromDatabase(7,null,null,null) as uidNamePosition[];
                        for (let i = 0;i < userDataList.length ; i++){
                            if (user.uid === userDataList[i].uid){
                                if (userDataList[i].position === "cto"){
                                    setIsCto(true)
                                }else if(userDataList[i].position === "ceo"){
                                    setIsCto(false);
                                }else {
                                    alert("您没有权限访问该页面");
                                    LogoutFunction();
                                    window.location.href = "/evaluation/login";
                                }
                            }
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
                fetchData().catch((Error) => {
                    console.log(Error);
                });
            }else {
                window.location.href = "/evaluation/login";
            }

        })
    }, []);

    useEffect(() => {
        fetchDataFromDatabase(5, null, null, null).then((data) => {
            data ? setEmployeesQuantity(data as number) : setEmployeesQuantity(0);
        });

        fetchDataFromDatabase(6, null, null, null)
            .then((data) => {
                const seasonData = data as string[];
                const mapDataList: valueAndLabel[] = [];
                for (let i = 0; i < seasonData.length; i++) {
                    const mapData: valueAndLabel = {value: "", label: ""};
                    mapData.value = seasonData[i];
                    mapData.label = `${seasonData[i].slice(0, 4)}年第${seasonData[
                        i
                        ].slice(6)}季度`;
                    mapDataList.push(mapData);
                }
                setReceivedSeasonData(mapDataList);
            })
            .catch((Error) => {
                console.log(Error);
            });

        fetchDataFromDatabase(1, null, null, null)
            .then((data) => {
                const positionBox: string[] = ["技术人员", "BSE"];
                const newItems2: MenuProps["items"] = [UserOutlined, UserOutlined].map(
                    (icon, index) => {
                        const key = String(index + 1);
                        const filterData = data as uidNamePosition[];
                        const names = filterData
                            ? filterData.filter(
                                (employee: uidNamePosition) =>
                                    employee.position === (index === 0 ? "tech" : "bse")
                            )
                            : [];
                        return {
                            key: `sub${key}`,
                            icon: React.createElement(icon),
                            label: positionBox[index],

                            children: names.map((employee: uidNamePosition) => {
                                const subKey = [employee.uid, employee.position];
                                return {
                                    key: subKey,
                                    label: employee.name,
                                };
                            }),
                        };
                    }
                );
                setItems2(newItems2);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        function checkAndUpdateData() {
            findLatestDoc().then((data) => {
                if (data) {
                    if (data.docData.startDate !== startDate) {
                        setStartDate(data.docData.startDate);
                    }
                    if (data.docData.endDate1 !== endDate1) {
                        setEndDate1(data.docData.endDate1);
                    }
                    if (data.docData.endDate2 !== endDate2) {
                        setEndDate2(data.docData.endDate2);
                    }
                }
            });
        }

        intervalIdRef.current = setInterval(checkAndUpdateData, 3500);

        return () => {
            if (intervalIdRef.current) clearInterval(intervalIdRef.current);
        };
    }, [endDate1, endDate2, startDate]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (intervalIdRef.current) clearInterval(intervalIdRef.current);
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);


    return (
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        siderBg: "#00FFFF",
                        footerBg: "#00FFFF",
                    },
                },
            }}
        >
            <Layout>
                <Header style={{display: "flex", alignItems: "center"}}>
                    <div className="demo-logo"/>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={["后台评价系统"]}
                        items={items1}
                        style={{flex: 1, minWidth: 0}}
                        onClick={(e) => {
                            setTitle(e.key);
                            handleMenuClick(employeesUid, employeesPosition).catch(
                                (Error) => {
                                    console.log(Error);
                                }
                            );
                        }}
                    />
                    <Space wrap>
                        <Select
                            defaultValue={season}
                            value={season}
                            onChange={(value) => {
                                setSeason(value);
                                handleMenuClick(employeesUid, employeesPosition, value).catch(
                                    (Error) => {
                                        console.log(Error);
                                    }
                                );
                            }}
                            style={{width: 150}}
                            options={receivedSeasonData}
                        />
                    </Space>
                    <div style={{marginLeft: "20px"}}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={{xs: 0.5, sm: 1.5}}
                        >
                            <AccountPopover isCTO={isCto}/>
                        </Stack>
                    </div>
                </Header>
                <div
                    style={{display: "flex", alignItems: "center"}}
                    className="书论京成"
                >
                    <div>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img
                            src={imageSrc}
                            alt="Image"
                            style={{
                                width: "200px",
                                height: "auto",
                                marginLeft: "10px",
                                marginRight: "17px",
                            }}
                        />
                    </div>
                    <div>{BasicCard("公司成员", employeesQuantity, true)}</div>
                    <div>{BasicCard("当前时间", getDate())}</div>
                    <div>{BasicCard("自评开始时间", startDate)}</div>
                    <div>{BasicCard("自评结束时间", endDate1)}</div>
                    <div>{BasicCard("复评结束时间", endDate2)}</div>
                    <div className="进度">{selectedProgress()}</div>
                    <div className="季度">
                        <PieChart
                            series={[
                                {
                                    data: [
                                        {id: 0, value: 10, label: "series A"},
                                        {id: 1, value: 15, label: "series B"},
                                        {id: 2, value: 20, label: "series C"},
                                    ],
                                },
                            ]}
                            height={50}
                        />
                    </div>
                </div>
                <Layout>
                    <ConfigProvider
                        theme={{
                            token: {
                                borderRadius: 60,
                            },
                        }}
                    >
                        <Sider
                            width={200}
                            style={{background: colorBgContainer}}
                            collapsed={false}
                        >
                            <Menu
                                mode="inline"
                                defaultOpenKeys={["sub1", "sub2"]}
                                style={{height: "100%", borderRight: 0}}
                                items={items2}
                                onClick={(e) => {
                                    const list = e.key.split(",");
                                    setEmployeesUid(list[0]);
                                    setEmployeesPosition(list[1]);
                                    handleMenuClick(list[0], list[1]).catch((Error) => {
                                        console.log(Error);
                                    });
                                }}
                            />
                        </Sider>
                    </ConfigProvider>
                    <Container maxWidth={false}>
                        <Content
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            {selectedComponent}
                        </Content>
                    </Container>
                </Layout>
            </Layout>
        </ConfigProvider>
    );
};
