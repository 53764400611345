import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MiddleTitle, BoardTitle } from "../home";

export const Philosophy: React.FC = () => {
  const { t } = useTranslation();
  const array = [
    { id: "1", content: t("philosophy.business_policy.content.text_1") },
    { id: "2", content: t("philosophy.business_policy.content.text_2") },
    { id: "3", content: t("philosophy.business_policy.content.text_3") },
    { id: "4", content: t("philosophy.business_policy.content.text_4") },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Box
        className="about"
        sx={{
          width: "100%",
          pt: "85px",
          position: "relative",
          textAlign: "center",
          "@media screen and (min-width: 900px)": {
            pt: "44px",
          },
        }}
      >
        <Box sx={{ margin: "0 auto", textAlign: "center" }}>
          <BoardTitle arrow="center">
            <Typography
              component="h2"
              fontWeight="bold"
              whiteSpace="normal"
              sx={{
                fontSize: "30px",
                "@media screen and (min-width: 900px)": {
                  fontSize: "44px",
                  letterSpacing: "2px",
                },
              }}
            >
              {t("home.directions.title_top")}
            </Typography>
            <Typography
              fontWeight="bold"
              mt="10px"
              sx={{
                fontSize: "13px",
                "@media screen and (min-width: 900px)": {
                  fontSize: "16px",
                  letterSpacing: "1.3px",
                },
              }}
            >
              {t("home.directions.title_bottom")}
            </Typography>
          </BoardTitle>
        </Box>
        <Box
          className="bg"
          sx={{
            position: "relative",
            zIndex: "1",
            overflow: "hidden",
            mt: "32px",
            width: "100%",
            height: "200px",
            "& img": {
              height: "100%",
              width: "100%",
            },
            "@media screen and (min-width: 900px)": {
              mt: "64px",
              height: "360px",
            },
          }}
        >
          <img
            src={require("src/assets/images/philosophy_bg.png")}
            alt=""
            width={2000}
            height={2000}
          />
        </Box>
      </Box>
      <Box
        className="introduction"
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <Box
          className="contain_main"
          sx={{
            position: "relative",
            zIndex: "1",
            top: "-36px",
            margin: "0 auto -36px",
            width: "88%",
            "@media screen and (min-width: 900px)": {
              top: "-68px",
              margin: "0 auto -68px",
              maxWidth: "1200px",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              top: "0",
              right: "0",
              left: "0",
              margin: "auto",
              width: "100%",
              height: "100%",
              "@media screen and (min-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
              }}
            >
              <Box
                className="overview"
                sx={{
                  height: "78px",
                  display: "flex",
                  alignItems: "center",
                  ml: "7.5%",
                  "& span": {
                    position: "relative",
                    fontSize: "13px",
                    fontWeight: "bold",
                  },
                }}
              >
                <MiddleTitle text={t("home.directions.title_top")} />
              </Box>
            </Box>
            <Box
              className="intro"
              sx={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <BoardTitle arrow="center">
                <Typography
                  component="h3"
                  whiteSpace="normal"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    "@media screen and (min-width: 900px)": {
                      fontSize: "32px",
                      letterSpacing: "2px",
                    },
                  }}
                >
                  {t("philosophy.corporate_philosophy.title")}
                </Typography>
              </BoardTitle>
            </Box>
            <Box
              className="tp"
              sx={{
                margin: "48px auto 0",
                height: "250px",
                "& img": {
                  width: "100%",
                  height: "100%",
                },
                "@media screen and (min-width: 900px)": {
                  height: "650px",
                },
              }}
            >
              <img
                src={require("src/assets/images/chess.jpg")}
                alt=""
                width={2000}
                height={2000}
              />
            </Box>
            <Box
              className="intro_text"
              sx={{
                padding: "17px 12px 40px",
                "& p": {
                  fontSize: "13px",
                  lineHeight: "2.2",
                },
                ".title": { fontWeight: "bold" },
                "@media screen and (min-width: 900px)": {
                  padding: "40px 13% 70px",
                  "& p": {
                    fontSize: "16px",
                  },
                },
              }}
            >
              <Typography className="title">
                {t("philosophy.corporate_philosophy.title")}：
              </Typography>
              <Typography>
                ●&nbsp;&nbsp;
                {t("philosophy.corporate_philosophy.content.text")}
              </Typography>
              <br />
              <br />
            </Box>
            <Box
              className="intro"
              sx={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <BoardTitle arrow="center">
                <Typography
                  component="h3"
                  whiteSpace="normal"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    "@media screen and (min-width: 900px)": {
                      fontSize: "32px",
                      letterSpacing: "2px",
                    },
                  }}
                >
                  {t("philosophy.business_policy.title")}
                </Typography>
              </BoardTitle>
            </Box>
            <Box
              className="tp"
              sx={{
                margin: "48px auto 0",
                height: "200px",
                "& img": {
                  width: "100%",
                  height: "100%",
                },
                "@media screen and (min-width: 900px)": {
                  height: "300px",
                },
              }}
            >
              <img
                src={require("src/assets/images/news_bg.png")}
                alt=""
                width={2000}
                height={2000}
              />
            </Box>
            <Box
              className="intro_text"
              sx={{
                padding: "17px 12px 40px",
                "& p": {
                  fontSize: "13px",
                  lineHeight: "2.2",
                },
                ".title": { fontWeight: "bold" },
                "@media screen and (min-width: 900px)": {
                  padding: "40px 13% 70px",
                  "& p": {
                    fontSize: "16px",
                  },
                },
              }}
            >
              <Typography className="title">
                {t("philosophy.business_policy.title")}：
              </Typography>
              {array.map((item) => (
                <Typography key={item.id}>
                  ● &nbsp;&nbsp;{item.content}
                </Typography>
              ))}
              <br />
              <br />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        className="introduction_bg_main"
        sx={{
          position: "absolute",
          maxWidth: "1200px",
          margin: "auto",
          top: "0",
          right: "0",
          left: "0",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #f5f5f5",
          "& span": {
            background: "#f5f5f5",
            position: "absolute",
            top: "0",
            width: "1px",
            height: "100%",
            margin: "auto",
          },
          ".line_1": {
            left: "0",
          },
          ".line_2": {
            left: "25%",
          },
          ".line_3": {
            left: "50%",
          },
          ".line_3:after": {
            top: "16px",
            width: "1px",
            height: "12px",
            content: `" "`,
            position: "absolute",
          },
          ".line_4": {
            right: "25%",
          },
          ".line_5": {
            right: "0",
          },
          "@media screen and (min-width: 900px)": {
            width: "85%",
            maxWidth: "1200px",
            ".line_1": {
              left: "7.5%",
            },
            ".line_2": {
              left: "28.75%",
            },
            ".line_3:after": {
              top: "28px",
              width: "1px",
              height: "20px",
            },
            ".line_4": {
              right: "28.75%",
            },
            ".line_5": {
              right: "7.5%",
            },
          },
        }}
      >
        <Typography component="span" className="line_1"></Typography>
        <Typography component="span" className="line_2"></Typography>
        <Typography component="span" className="line_3"></Typography>
        <Typography component="span" className="line_4"></Typography>
        <Typography component="span" className="line_5"></Typography>
      </Box>
    </Box>
  );
};
export default Philosophy;
