import { dateType } from "../pages/EmployeesPage";
export const getDate = () => {
  const currentDate = new Date();
  return currentDate.toISOString().slice(0, 10);
};

export function getDateDifference(start: string, end: string): number {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
  return differenceInMilliseconds / (1000 * 3600 * 24);
}

export function getDateDifferencePercent(
  now: string,
  start: string,
  end: string
): number {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const nowDate = new Date(now);

  const totalDays =
    Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

  const currentDays =
    Math.abs(nowDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

  const percent = (currentDays / totalDays) * 100;

  return Math.round(percent);
}

export function getSeasonNumber(data: dateType): number {
  if (
    getDateDifferencePercent(getDate(), data.startDate, data.endDate1) <= 100 &&
    getDateDifferencePercent(getDate(), data.startDate, data.endDate2) < 100
  ) {
    return 1;
  } else if (
    getDateDifferencePercent(getDate(), data.startDate, data.endDate1) > 100 &&
    getDateDifferencePercent(getDate(), data.startDate, data.endDate2) < 100
  ) {
    return 2;
  } else {
    return 3;
  }
}
