import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout } from "src/components/common";
import { Box, Typography } from "@mui/material";
import { BoardTitle, MiddleTitle, MoreButton } from "src/components/home";
import { useTranslation } from "react-i18next";

export const ProductsPage: React.FC = () => {
  const { t } = useTranslation();
  const array = [
    {
      id: "1",
      text: t("products.list.tag_1.title"),
      link: "",
      content: [
        {
          title: t("products.list.tag_1.content.title"),
          children: [
            t("products.list.tag_1.content.text_1"),
            t("products.list.tag_1.content.text_2"),
            t("products.list.tag_1.content.text_3"),
            t("products.list.tag_1.content.text_4"),
            t("products.list.tag_1.content.text_5"),
            t("products.list.tag_1.content.text_6"),
          ],
          summary: [],
        },
      ],
      lang: "ja",
    },
    {
      id: "2",
      text: t("products.list.tag_2.title"),
      link: "",
      content: [
        {
          title: t("products.list.tag_2.content.title_1"),
          children: [
            t("products.list.tag_2.content.text_1"),
            t("products.list.tag_2.content.text_2"),
            t("products.list.tag_2.content.text_3"),
            t("products.list.tag_2.content.text_4"),
            t("products.list.tag_2.content.text_5"),
            t("products.list.tag_2.content.text_6"),
          ],
          summary: [],
        },
      ],
      lang: "ja",
    },
    {
      id: "3",
      text: t("products.list.tag_3.title"),
      link: "",
      content: [
        {
          title: t("products.list.tag_3.content.title_1"),
          children: [
            t("products.list.tag_3.content.text_1"),
            t("products.list.tag_3.content.text_2"),
            t("products.list.tag_3.content.text_3"),
            t("products.list.tag_3.content.text_4"),
            t("products.list.tag_3.content.text_5"),
          ],
          summary: [
            t("products.list.tag_3.content.summary_1"),
            t("products.list.tag_3.content.summary_2"),
          ],
        },
        {
          title: t("products.list.tag_3.content.title_2"),
          children: [
            t("products.list.tag_3.content.text_7"),
            t("products.list.tag_3.content.title_3"),
            t("products.list.tag_3.content.text_8"),
            t("products.list.tag_3.content.text_9"),
            t("products.list.tag_3.content.text_10"),
            t("products.list.tag_3.content.title_4"),
            t("products.list.tag_3.content.text_11"),
            t("products.list.tag_3.content.text_12"),
            t("products.list.tag_3.content.text_13"),
            t("products.list.tag_3.content.text_14"),
          ],
          summary: [
            t("products.list.tag_3.content.summary_3"),
            t("products.list.tag_3.content.summary_4"),
          ],
        },
      ],
      lang: "ja",
    },
  ];

  const divRef_1 = useRef<HTMLDivElement | null>(null);
  const divRef_2 = useRef<HTMLDivElement | null>(null);
  const [divToTopHeight1, setDivToTopHeight1] = useState<number>(0);
  const [divToTopHeight2, setDivToTopHeight2] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const handleScroll = useCallback(() => {
    setScrollHeight(window.scrollY);
  }, []);

  useEffect(() => {
    if (divRef_1.current) {
      const rect = divRef_1.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight1(distanceFromTop);
    }
    if (divRef_2.current) {
      const rect = divRef_2.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight2(distanceFromTop);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Layout>
      <Box
        sx={{
          color: "#000",
          p: "50px 0 0",
          backgroundColor: "#fff",
          "@media screen and (max-width: 900px)": { padding: "30px 0 0" },
        }}
      >
        <Box
          maxWidth={1200}
          m="0 auto 50px"
          sx={{
            display: "flex",
            justifyContent: "center",
            "@media screen and (max-width: 900px)": {
              mb: "30px",
              "& p": { fontSize: 34 },
            },
          }}
        >
          <BoardTitle arrow="center" isLine>
            <Typography
              fontSize={50}
              lineHeight={1.2}
              letterSpacing={4}
              fontWeight="bold"
              textAlign="center"
              whiteSpace="normal"
            >
              {t("products.top")}
            </Typography>
          </BoardTitle>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 380,
            background: `url(${require("src/assets/images/chengdu1.jpeg")}) no-repeat center center`,
            backgroundSize: "cover",
            backgroundPosition: "0% 80%",
            opacity: 0.6,
            "@media screen and (max-width: 400px)": {
              height: 300,
            },
          }}
        />
      </Box>
      <Box sx={{ backgroundColor: "#fff", pt: "68px", pb: "68px" }}>
        <Box
          sx={{
            width: "88%",
            maxWidth: 1200,
            height: 68,
            background: "#fff",
            margin: "0 auto -68px",
            position: "relative",
            top: -136,
            zIndex: 9,
            "& >.MuiBox-root": {
              width: "85%",
              height: "100%",
              m: "0 auto",
              "& p": { lineHeight: "68px" },
            },
            "@media screen and (max-width: 900px)": { top: -110 },
          }}
        >
          <MiddleTitle text={t("products.top")} />
        </Box>
        {array.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              p: "100px 0",
              backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#fff",
              position: "relative",
              "@media screen and (max-width: 900px)": { padding: "30px 0" },
            }}
          >
            <Box
              sx={{
                width: index % 2 === 0 ? "44%" : "50%",
                height: "100%",
                background: `url(${require(`src/assets/images/service_${
                  index + 1
                }.jpeg`)}) no-repeat center center`,
                backgroundSize: "cover",
                position: "absolute",
                left: index % 2 === 0 ? "0%" : "unset",
                right: index % 2 !== 0 ? "0%" : "unset",
                top: 0,
                "@media screen and (max-width: 900px)": {
                  height: "100%",
                },
              }}
            />
            <Box
              sx={{
                width: index % 2 === 0 ? "44%" : "50%",
                height: "100%",
                // background: `linear-gradient(to right, ${
                //  index % 2 === 0 ? "#f5f5f5" : "#fff"
                // } 10%, transparent 85%, ${
                //  index % 2 === 0 ? "#f5f5f5" : "#fff"
                //  } 100%)`,
                // background: `linear-gradient(to right, ${
                //  index % 2 === 0 ? "#f5f5f5" : "#fff"
                // }, ${index % 2 === 0 ? "#f5f5f5" : "#fff"},${
                //  index % 2 === 0 ? "#f5f5f5" : "#fff"
                // })`,
                background: `linear-gradient(to right, ${
                  index % 2 === 0 ? "#f5f5f5" : "#fff"
                } 0%, rgba(128, 128, 128, 0) 50%), linear-gradient(to right, rgba(128, 128, 128, 0) 50%, ${
                  index % 2 === 0 ? "#f5f5f5" : "#fff"
                } 100%)`,
                maskImage:
                  "radial-gradient(circle at center, transparent 10%, white 42%)",
                // "radial-gradient(ellipse at center, transparent 20%, white 72%)",
                position: "absolute",
                left: index % 2 === 0 ? "0%" : "unset",
                right: index % 2 !== 0 ? "0%" : "unset",
                top: 0,
                "@media screen and (max-width: 900px)": {
                  height: "100%",
                  maskImage:
                    "radial-gradient(ellipse at center, transparent 10%, white 72%)",
                },
              }}
            />
            <Box
              sx={{
                width: "88%",
                maxWidth: 1200,
                m: "0 auto",
                display: "flex",
                justifyContent: index % 2 === 0 ? "flex-end" : "flex-start",
              }}
            >
              <Box
                width="50%"
                color="#000"
                sx={{
                  zIndex: 3,
                  "& >.MuiBox-root:last-of-type": {
                    m: "43px 0 0",
                    backgroundColor: "#fff !important",
                  },
                  "@media screen and (max-width: 900px)": {
                    width: "65%",
                    "& >.MuiBox-root:last-of-type": {
                      width: "100%",
                      m: "20px 0 0",
                      "& a": { width: "90%", p: "10px 5% !important" },
                    },
                  },
                }}
              >
                <Box
                  ref={index === 0 ? null : index === 1 ? divRef_1 : divRef_2}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <BoardTitle
                    isLine={
                      index === 0
                        ? true
                        : index === 1
                        ? window.innerHeight + scrollHeight >
                          divToTopHeight1 + 60
                          ? true
                          : false
                        : window.innerHeight + scrollHeight >
                          divToTopHeight2 + 60
                        ? true
                        : false
                    }
                  >
                    <Typography
                      fontSize={26}
                      lineHeight={1.2}
                      letterSpacing={4}
                      fontWeight="bold"
                      whiteSpace="normal"
                      sx={{
                        "@media screen and (max-width: 900px)": {
                          fontSize: 18,
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          // whiteSpace: "nowrap",
                        },
                      }}
                    >
                      {item.text}
                    </Typography>
                  </BoardTitle>
                </Box>
                {item.content.map((i, contentIndex) => (
                  <Box key={contentIndex}>
                    <Typography
                      key={index}
                      mt="27px"
                      sx={{
                        fontWeight: "bold",
                        "@media screen and (max-width: 900px)": {
                          fontSize: 13,
                          mt: 2,
                          display: "-webkit-box",
                          "-webkit-line-clamp": "2",
                          "-webkit-box-orient": "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      {i.title}
                    </Typography>
                    {(index === 0
                      ? i.children
                      : index === 1
                      ? i.children
                      : i.children.slice(0, 1)
                    ).map((child, childIndex) => (
                      <Typography
                        key={childIndex}
                        fontSize={13}
                        mt={index < 2 ? "16px" : "24px"}
                        sx={{
                          pl: 4,
                          position: "relative",
                          "&:before": {
                            content: '""',
                            width: 5,
                            height: 5,
                            display: "block",
                            position: "absolute",
                            top: 10,
                            left: 16,
                            backgroundColor: "#000",
                            borderRadius: "50%",
                          },
                          "@media screen and (max-width: 900px)": {
                            fontSize: 13,
                            mt: 2,
                            pl: 2,
                            "&:before": { left: 4 },
                            display: index !== 2 ? "block" : "-webkit-box",
                            "-webkit-line-clamp": index !== 2 ? "unset" : "2",
                            "-webkit-box-orient":
                              index !== 2 ? "unset" : "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: index !== 2 ? "nowrap" : "unset",
                          },
                        }}
                      >
                        {child}
                      </Typography>
                    ))}
                  </Box>
                ))}
                <MoreButton
                  text={t("products.list.tag_1.details_url")}
                  link={`/service/detail?id=${item.id}`}
                  isArrow
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Layout>
  );
};

export default ProductsPage;
