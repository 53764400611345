import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BoardTitle, MoreButton } from ".";
import { useTranslation } from "react-i18next";

type PropsType = {};
export const Philosophy: React.FC<PropsType> = (props) => {
  const { t, i18n } = useTranslation();
  const divRef = useRef<HTMLDivElement | null>(null);
  const [divToTopHeight, setDivToTopHeight] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const handleScroll = useCallback(() => {
    setScrollHeight(window.scrollY);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      const rect = divRef.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight(distanceFromTop);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box
      ref={divRef}
      sx={{
        width: "100%",
        height: "auto",
        maxWidth: "1200px",
        m: "auto",
        padding: "70px 0",
        position: "relative",
        textAlign: "center",
      }}
    >
      <Typography
        sx={{
          width: "100%",
          fontSize: "14rem",
          overflow: "hidden",
          wordWrap: "normal",
          fontWeight: "bold",
          lineHeight: 1,
          letterSpacing: "-3px",
          color: "rgba(0, 0, 0, 0.02)",
          position: "absolute",
          left: 0,
          top: "25px",
          textAlign: "center",
          "@media screen and (max-width: 900px)": {
            fontSize: "5.6rem",
            top: 60,
          },
        }}
      >
        {t("home.directions.bg_title")}
      </Typography>
      <Box
        sx={{
          "@media screen and (max-width: 900px)": { display: "none" },
        }}
      >
        <BoardTitle
          arrow="center"
          isLine={
            window.innerHeight + scrollHeight > divToTopHeight + 220
              ? true
              : false
          }
        >
          <Typography
            fontSize={50}
            lineHeight={1.2}
            letterSpacing={4}
            fontWeight="bold"
          >
            {t("home.directions.title_top")}
          </Typography>
          <Typography fontSize={18} fontWeight="bold" mt="20px">
            {t("home.directions.title_bottom")}
          </Typography>
        </BoardTitle>
      </Box>
      <Box
        sx={{
          "@media screen and (min-width: 900px)": { display: "none" },
        }}
      >
        <BoardTitle
          arrow="center"
          isLine={
            window.innerHeight + scrollHeight > divToTopHeight + 220
              ? true
              : false
          }
        >
          <Typography
            fontSize={42}
            lineHeight={1.2}
            letterSpacing={4}
            fontWeight="bold"
          >
            {t("home.directions.title_top")}
          </Typography>
          <Typography fontSize={18} fontWeight="bold" mt="20px">
            {t("home.directions.title_bottom")}
          </Typography>
        </BoardTitle>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: 365,
          color: "#000",
          textAlign: "center",
          mt: "80px",
          padding: "150px 0",
          position: "relative",
          "@media screen and (max-width: 900px)": {
            height: i18n.language === "zh" ? 275 : 360,
            padding: "35px 0 35px",
            mt: "40px",
          },
        }}
      >
        <Box
          sx={{
            width: "50%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            background: `url(${require("src/assets/images/chengdu3.jpeg")}) no-repeat center`,
            backgroundSize: "cover, cover",
            opacity: 0.7,
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            width: "50%",
            height: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            background: `url(${require("src/assets/images/panda1.jpeg")}) no-repeat center`,
            backgroundSize: "cover",
            opacity: 0.7,
            zIndex: 1,
          }}
        />
        <Box
          p="50px 60px"
          width="510px"
          sx={{
            m: "0 auto",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 2,
            position: "inherit",
            "& .MuiBox-root": { width: "280px !important" },
            "@media screen and (max-width: 900px)": {
              width: "300px",
              padding: "15px 20px",
              "& p": { m: "0 auto 15px" },
              "& .MuiBox-root": {
                width: "unset !important",
                "& a": { m: "0 auto" },
              },
            },
          }}
        >
          <Typography m="38px auto 24px" fontSize={14} lineHeight={2}>
            {t("home.directions.text.tag_1")}
            <br />
            {t("home.directions.text.tag_2")}
            <br />
            {t("home.directions.text.tag_3")}
            {i18n.language === "zh" && (
              <>
                <br />
                {t("home.directions.text.tag_4")}
              </>
            )}
          </Typography>
          <MoreButton
            text={t("home.directions.list_url")}
            link="/recruit"
            isArrow
          />
        </Box>
      </Box>
    </Box>
  );
};
export default Philosophy;
