import { useState } from "react";
import IconButtonAnimate from "../common/IconButtonAnimate";
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, MenuItem, Avatar } from "@mui/material";
import MenuPopover from "../common/MenuPopover";
import { blue } from "@mui/material/colors";
import { LogoutFunction } from "../home/logoutFunction";
import { FormDialog } from "../home/dialog";
import * as React from "react";
interface AccountPopoverProps {
  isCTO: boolean;
}
export const AccountPopover: React.FC<AccountPopoverProps> = ({ isCTO }) => {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClickMenuItem = () => {
    setOpenDialog(true); // 点击 MenuItem 后设置 openDialog 为 true，显示对话框
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleLogout = async () => {
    LogoutFunction()
  };

  return (
    <>
      {openDialog && (
        <FormDialog
          open1={openDialog}
          handleClickOpen={handleClickMenuItem}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar sx={{ bgcolor: blue[400] }}>{isCTO ? "李" : "宋"}</Avatar>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap></Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
            noWrap
          ></Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={handleClickMenuItem} sx={{ m: 1 }}>
          评价时间设置
        </MenuItem>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          登出
        </MenuItem>
      </MenuPopover>
    </>
  );
};
