import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BoardTitle, MessageItem, MoreButton } from ".";
import { useTranslation } from "react-i18next";

type PropsType = {};

export const News: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();
  const messages = [
    {
      time: "2023.07.21",
      tag: t("home.news.tag"),
      text: t("home.news.list.option8"),
      url: "",
    },
    {
      time: "2023.07.09",
      tag: t("home.news.tag"),
      text: t("home.news.list.option7"),
      url: "",
    },
    {
      time: "2023.07.06",
      tag: t("home.news.tag"),
      text: t("home.news.list.option6"),
      url: "",
    },
    {
      time: "2023.06.10",
      tag: t("home.news.tag"),
      text: t("home.news.list.option1"),
      url: "",
    },
    {
      time: "2023.06.01",
      tag: t("home.news.tag"),
      text: t("home.news.list.option2"),
      url: "",
    },
    {
      time: "2023.05.25",
      tag: t("home.news.tag"),
      text: t("home.news.list.option3"),
      url: "",
    },
    {
      time: "2023.05",
      tag: t("home.news.tag"),
      text: t("home.news.list.option4"),
      url: "",
    },
    {
      time: "2023.04",
      tag: t("home.news.tag"),
      text: t("home.news.list.option5"),
      url: "",
    },
  ];
  const divRef = useRef<HTMLDivElement | null>(null);
  const [divToTopHeight, setDivToTopHeight] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const handleScroll = useCallback(() => {
    setScrollHeight(window.scrollY);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      const rect = divRef.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight(distanceFromTop);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box
      ref={divRef}
      sx={{
        width: "85%",
        margin: "0 auto",
        padding: "68px 0 80px",
        display: "flex",
        flexWrap: "wrap",
        "@media screen and (max-width: 900px)": {
          width: "100% !important",
          padding: "30px 0 80px",
        },
      }}
    >
      <Box
        width={0}
        sx={{ "@media screen and (max-width: 900px)": { display: "none" } }}
      >
        <BoardTitle
          isLine={
            window.innerHeight + scrollHeight > divToTopHeight + 68
              ? true
              : false
          }
        >
          <Typography component="h2" fontSize={26} fontWeight="bold">
            {t("home.news.top_title")}
          </Typography>
          <Typography fontSize={16} fontWeight="bold" mt="10px">
            {t("home.news.bottom_title")}
          </Typography>
        </BoardTitle>
      </Box>
      <Box
        sx={{
          m: "0 auto 40px",
          textAlign: "center",
          "@media screen and (min-width: 900px)": { display: "none" },
        }}
      >
        <BoardTitle
          arrow="center"
          isLine={
            window.innerHeight + scrollHeight > divToTopHeight + 30
              ? true
              : false
          }
        >
          <Typography component="h2" fontSize={26} fontWeight="bold">
            {t("home.news.top_title")}
          </Typography>
          <Typography fontSize={16} fontWeight="bold" mt="10px">
            {t("home.news.bottom_title")}
          </Typography>
        </BoardTitle>
      </Box>
      <Box
        ml="20%"
        width="80%"
        mb="50px"
        sx={{
          "@media screen and (max-width: 900px)": { width: "100%", ml: 0 },
        }}
      >
        {(messages.length >= 3 ? messages.slice(0, 3) : messages).map(
          (item, index) => (
            <MessageItem
              key={index}
              item={item}
              isOdd={index % 2 === 0 ? true : false}
              isOmit
            />
          )
        )}
      </Box>
      <MoreButton text={t("home.news.button_title")} link="/news" isArrow />
    </Box>
  );
};
export default News;
