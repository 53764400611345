import React, {useEffect, useState} from "react";
import type {RadioChangeEvent} from "antd";
import {Radio, Result, Select} from "antd";
import {
    fetchDataFromDatabase,
    findLatestDoc,
    uploadInterReviews,
    uidName,
} from "../home/dataExchange";
import {MuiButton} from "../icons/MuiButtons";
import {getSeasonNumber} from "../home/Time";
import type {dateType} from "../pages/EmployeesPage";
import {getAuth, onAuthStateChanged} from "firebase/auth";

interface RadioGroupProps {
    selectedValue: number | undefined;
    onChange: (value: number | undefined) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({selectedValue, onChange}) => {
    const handleChange = (e: RadioChangeEvent) => {
        onChange(e.target.value);
    };

    return (
        <Radio.Group onChange={handleChange} value={selectedValue}>
            {[1, 2, 3, 4, 5].map((num) => (
                <Radio key={num} value={num}>
                    {num}
                </Radio>
            ))}
        </Radio.Group>
    );
};

export const UploadSuccessPage: React.FC = () => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [employeesUid,setEmployeesUid] = useState<string>("");
    const [uid, setUid] = useState<string[]>([]);
    const [name, setName] = useState<string[]>([]);
    const [uidAndName, setUidAndName] = useState<uidName[]>([]);
    const filteredOptions = uidAndName.filter((o) => !name.includes(o.name));
    const [values, setValues] = useState<number[]>([]);
    const [seasonName, setSeasonName] = useState<string>("");
    const [isInProgress, setIsInProgress] = useState<boolean>(false);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth,(user) =>{
            if (user){
                setEmployeesUid(user.uid);
            }else {
                window.location.href = "/evaluation/login";
            }
        })
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const receive = await findLatestDoc();
            if (receive) {
                setSeasonName(receive.docId);
                const needCalculateData: dateType = {
                    startDate: receive.docData.startDate,
                    endDate1: receive.docData.endDate1,
                    endDate2: receive.docData.endDate2,
                };
                const calculatedSeasonNumber = getSeasonNumber(needCalculateData);
                if (calculatedSeasonNumber !== 1) {
                    alert("非互评期间！");
                    window.location.href = "/evaluation/front";
                }
            }
        };
        fetchData().catch((Error) => {
            console.log(Error);
        });
    }, []);

    useEffect(() => {
        window.localStorage.removeItem("secondTime");
        const nameAndUidList: uidName[] = [];
        fetchDataFromDatabase(3, null, null, null).then((employeesUidName) => {
            console.log(employeesUidName);
            const EmployeesUidAndName = employeesUidName as uidName[];
            for (let i = 0; i < EmployeesUidAndName.length; i++) {
                if (
                    EmployeesUidAndName[i].uid !== employeesUid
                ) {
                    nameAndUidList.push(EmployeesUidAndName[i]);
                }
            }
            setUidAndName(nameAndUidList);
            setValues(Array(selectedItems.length).fill(undefined));
        });
    }, [employeesUid,selectedItems.length]);

    useEffect(() => {
        const uidList: string[] = [];
        const nameList: string[] = [];
        for (let i = 0; i < selectedItems.length; i++) {
            uidList.push(selectedItems[i].split("-")[1]);
            nameList.push(selectedItems[i].split("-")[0]);
        }
        setUid(uidList);
        setName(nameList);
    }, [selectedItems]);
    const handleRadioChange = (index: number, value: number | undefined) => {
        const newValues = [...values];
        if (value) newValues[index] = value;
        setValues(newValues);
    };

    const justifyValues = (values: number[]) => {
        for (let i = 0; i < values.length; i++) {
            if (values[i] === undefined) {
                return false;
            }
        }
        return true;
    };

    const backToSignPage = async () => {
        setIsInProgress(true);
        if (window.localStorage.getItem("uploadInterviewPermission") !== null) {
            if (uid.length === values.length && justifyValues(values)) {
                uploadInterReviews(uid, values as number[], seasonName)
                    .then((p) => {
                        if (p) {
                            window.localStorage.setItem("isNotFirstTime", "true");
                            setIsInProgress(false);
                            window.localStorage.removeItem("uploadInterviewPermission");
                            window.location.href = "/evaluation/front";
                        }
                    })
                    .catch((Error) => {
                        console.log(Error);
                    });
            } else {
                setIsInProgress(false);
                alert("您有未填写的项目");
            }
        }else {
            window.localStorage.setItem("isNotFirstTime", "true");
            window.location.href = "/evaluation/front";
        }
    };

    return (
        <>
            <Result
                status="success"
                title="成功上传数据!"
                extra={[
                    <MuiButton
                        isInProgress={isInProgress}
                        label={window.localStorage.getItem("uploadInterviewPermission") !== null?"上传并查看":"查看数据"}
                        onClick={backToSignPage}
                    />,
                ]}
            />
            <div>
                <div
                    style={{
                        textAlign: "center",
                        marginBottom: "20px",
                        fontSize: "medium",
                        color: "#a0a0a0",
                    }}
                >
                    {window.localStorage.getItem("uploadInterviewPermission") !== null && "请选择想要评价的同事："}
                </div>
                {window.localStorage.getItem("uploadInterviewPermission") !== null && <Select
                    mode="multiple"
                    placeholder="Inserted are removed"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    style={{width: "20%", left: "40%"}}
                    options={filteredOptions.map((item) => ({
                        value: `${item.name}-${item.uid}`,
                        label: item.name,
                    }))}
                />}
            </div>
            <div
            >
                {selectedItems.map((item, index) => (
                    <div
                        key={item}
                    >
                        <div style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px"
                        }}>
                            <div style={{position: "absolute", left: "39%", top: "50%", transform: "translateY(-50%)"}}>
                                {item.split("-")[0]}
                            </div>
                            <div style={{display: "grid", justifyContent: "center"}}>
                                <RadioGroup
                                    selectedValue={values[index]}
                                    onChange={(value) => handleRadioChange(index, value)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
