import React from "react";
import { Empty } from "antd";

export const EmptyIcon: React.FC = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <Empty />
    </div>
  );
};
