import { getAuth, signOut } from "firebase/auth";
const stateList: string[] = [
  "isNotFirstTime",
  "secondTime",
  "uploadInterviewPermission",
  "isSubmit",
  "emailForSignIn"
];
export const LogoutFunction = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
  for (let i = 0; i < stateList.length; i++) {
    const state = stateList[i];
    window.localStorage.removeItem(state);
  }
};
