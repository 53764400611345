import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BoardTitle, MiddleTitle } from "src/components/home";
import { Layout } from "src/components/common";
import { useTranslation } from "react-i18next";

export const AboutPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const array = [
    {
      name: t("about.about.content.name"),
      value: t("about.about.content.name_text"),
    },
    {
      name: t("about.about.content.establishment"),
      value: t("about.about.content.establishment_text"),
    },
    {
      name: t("about.about.content.capital"),
      value: t("about.about.content.capital_text"),
    },
    {
      name: t("about.about.content.address"),
      value: t("about.about.content.address_text"),
    },
    {
      name: t("about.about.content.founder"),
      value: t("about.about.content.founder_text"),
    },
    {
      name: t("about.about.content.business"),
      value: t("about.about.content.business_text"),
    },
    { name: t("about.about.content.number"), value: "028-87442585" },
    {
      name: t("about.about.content.consultation"),
      value: "contact@shuluntc.com",
    },
    {
      name: t("about.about.content.job_consultation"),
      value: "hr@shuluntc.com",
    },
  ];

  const array2 = [
    { id: "1", content: t("philosophy.business_policy.content.text_1") },
    { id: "2", content: t("philosophy.business_policy.content.text_2") },
    { id: "3", content: t("philosophy.business_policy.content.text_3") },
    { id: "4", content: t("philosophy.business_policy.content.text_4") },
  ];

  const divRef_1 = useRef<HTMLDivElement | null>(null);
  const divRef_2 = useRef<HTMLDivElement | null>(null);
  const divRef_3 = useRef<HTMLDivElement | null>(null);
  const [divToTopHeight1, setDivToTopHeight1] = useState<number>(0);
  const [divToTopHeight2, setDivToTopHeight2] = useState<number>(0);
  const [divToTopHeight3, setDivToTopHeight3] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const handleScroll = useCallback(() => {
    setScrollHeight(window.scrollY);
  }, []);

  useEffect(() => {
    if (divRef_1.current) {
      const rect = divRef_1.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight1(distanceFromTop);
    }
    if (divRef_2.current) {
      const rect = divRef_2.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight2(distanceFromTop);
    }
    if (divRef_3.current) {
      const rect = divRef_3.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight3(distanceFromTop);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Layout>
      <Box
        sx={{
          width: "100%%",
          m: "0 auto",
          p: "40px 0 0",
          color: "#000",
          "@media screen and (max-width: 900px)": { padding: "30px 0 0" },
        }}
      >
        <Box
          maxWidth={1200}
          m="0 auto 50px"
          sx={{
            display: "flex",
            justifyContent: "center",
            "@media screen and (max-width: 900px)": {
              mb: "30px",
              "& p": { fontSize: 34 },
            },
          }}
        >
          <BoardTitle arrow="center" isLine>
            <Typography
              fontSize={50}
              lineHeight={1.2}
              letterSpacing={4}
              fontWeight="bold"
              textAlign="center"
              whiteSpace="normal"
            >
              {t("about.title")}
            </Typography>
          </BoardTitle>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 380,
            background: `url(${require("src/assets/images/chengdu1.jpeg")}) no-repeat center center`,
            backgroundSize: "cover",
            backgroundPosition: "0% 80%",
            opacity: 0.6,
            "@media screen and (max-width: 400px)": {
              height: 300,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: "88%",
          maxWidth: 1200,
          height: "auto",
          background: "#fff",
          margin: "0 auto -68px",
          position: "relative",
          top: -68,
          zIndex: 9,
          "@media screen and (max-width: 900px)": {
            top: -38,
            margin: "0 auto -38px",
          },
        }}
      >
        <Box
          sx={{
            width: "85%",
            p: "0 0 80px",
            m: "0 auto",
            "& >.MuiBox-root:first-of-type": {
              height: 68,
              "& p": { lineHeight: "68px" },
            },
            "& >.MuiBox-root:nth-of-type(2)": { m: "70px auto 30px" },
            "& >.MuiBox-root:nth-of-type(4)": { m: "0px auto 30px" },
            "& >.MuiBox-root:nth-of-type(6)": { m: "70px auto 30px" },
            "@media screen and (max-width: 900px)": {
              width: "100%",
              "& >.MuiBox-root:first-of-type": {
                width: "85%",
                m: "0 auto",
              },
              "& >.MuiBox-root:nth-of-type(2)": {
                m: "40px auto 0px",
                "& p": { fontSize: 26 },
              },
              "& >.MuiBox-root:nth-of-type(4)": {
                m: "40px auto 0px",
                "& p": { fontSize: 26 },
              },
              "& >.MuiBox-root:nth-of-type(6)": {
                m: "40px auto 0px",
                "& p": { fontSize: 26 },
              },
            },
          }}
        >
          <MiddleTitle text={t("about.top")} />
          <Box
            ref={divRef_1}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BoardTitle
              arrow="center"
              isLine={
                window.innerHeight + scrollHeight > divToTopHeight1 + 68
                  ? true
                  : false
              }
            >
              <Typography
                fontSize={32}
                lineHeight={1.2}
                letterSpacing={4}
                fontWeight="bold"
                textAlign="center"
                whiteSpace="normal"
              >
                {t("about.presentation.title")}
              </Typography>
            </BoardTitle>
          </Box>
          <Box
            color="#000"
            sx={{
              p: "40px 13% 70px",
              "& p": { fontSize: 14, lineHeight: 2.2 },
              "@media screen and (max-width: 900px)": {
                p: "20px 5% 0px",
              },
            }}
          >
            <Typography>{t("about.presentation.content.text_1")}</Typography>
            <br />
            <Typography>{t("about.presentation.content.text_2")}</Typography>
            <br />
            <Typography>{t("about.presentation.content.text_3")}</Typography>
            <br />
            {i18n.language === "zh" && (
              <>
                <Typography>
                  {t("about.presentation.content.text_4")}
                </Typography>
                <br />
                <Typography>
                  {t("about.presentation.content.text_5")}
                </Typography>
                <br />
              </>
            )}
          </Box>
          <Box
            ref={divRef_2}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BoardTitle
              arrow="center"
              isLine={
                window.innerHeight + scrollHeight > divToTopHeight2 + 68
                  ? true
                  : false
              }
            >
              <Typography
                fontSize={32}
                lineHeight={1.2}
                letterSpacing={4}
                fontWeight="bold"
                textAlign="center"
                whiteSpace="normal"
              >
                {t("about.about.title")}
              </Typography>
            </BoardTitle>
          </Box>
          <Box
            pt={5}
            color="#000"
            sx={{
              "& dl": {
                display: "flex",
                minHeight: 65,
                m: "0",
                fontSize: 14,
              },
              "& dt": {
                width: "25%",
                p: "18px 30px",
                borderTop: "1px solid #f5f5f5",
                borderLeft: "1px solid #f5f5f5",
                borderRight: "1px solid #f5f5f5",
                position: "relative",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: "-1px",
                  left: "0",
                  width: "6px",
                  height: "1px",
                  background: "#000",
                },
              },
              "& dl:last-of-type ": {
                "& dt:after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-1px",
                  left: "0",
                  width: "6px",
                  height: "1px",
                  background: "#000",
                },
                "& dt, dd": { borderBottom: "1px solid #f5f5f5" },
              },
              "& dd": {
                width: "75%",
                p: "15px 30px",
                ml: 0,
                display: "flex",
                alignItems: "center",
                borderTop: "1px solid #f5f5f5",
                borderRight: "1px solid #f5f5f5",
              },
              "@media screen and (max-width: 900px)": {
                "& dt, dd": { p: "10px 15px !important" },
              },
            }}
          >
            {array.map((item, index) => (
              <dl key={index}>
                <dt>{item.name}</dt>
                <dd>
                  {index > array.length - 3 ? (
                    <a
                      href={`mailto:${item.value}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.value}
                    </a>
                  ) : (
                    item.value
                  )}
                </dd>
              </dl>
            ))}
          </Box>
          <Box
            ref={divRef_3}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BoardTitle
              arrow="center"
              isLine={
                window.innerHeight + scrollHeight > divToTopHeight3 + 68
                  ? true
                  : false
              }
            >
              <Typography
                fontSize={32}
                lineHeight={1.2}
                letterSpacing={4}
                fontWeight="bold"
                textAlign="center"
                whiteSpace="normal"
              >
                {t("philosophy.business_policy.title")}
              </Typography>
            </BoardTitle>
          </Box>

          <Box
            className="intro_text"
            sx={{
              padding: "17px 12px 40px",
              "& p": {
                fontSize: "13px",
                lineHeight: "2.2",
              },
              ".title": { fontWeight: "bold" },
              "@media screen and (min-width: 900px)": {
                padding: "40px 13% 0px",
                "& p": { fontSize: "16px" },
              },
            }}
          >
            {array2.map((item) => (
              <Typography key={item.id}>
                ● &nbsp;&nbsp;{item.content}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default AboutPage;
