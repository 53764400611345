import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type PropsType = {};

export const Footer: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        p: "32px 0",
        backgroundColor: "#000",
        color: "#fff",
        textAlign: "center",
        "& a": { p: "0 16px", color: "white", textDecoration: "none" },
        "& a:hover": { textDecoration: "underline" },
      }}
    >
      <Typography fontSize={12}>
        {t("footer.left")}
        <Link href="https://beian.miit.gov.cn/#/Integrated/index">
          {t("footer.center")}
        </Link>
      </Typography>
    </Box>
  );
};
export default Footer;
