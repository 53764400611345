import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ArrowFillDownIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 1024 1024" fill="none">
    <path
      fill="#fff"
      d="M163.397 289.169c-40.578 0-66.526 54.184-35.442 85.258l349.263 349.278c20.031 20.032 49.823 20.032 69.853 0l349.275-349.278c30.305-30.294 6.678-85.258-34.929-85.258h-698.02z"
    />
  </SvgIcon>
);

export default ArrowFillDownIcon;
