import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DatePicker, Space } from "antd";
import type { DatePickerProps } from "antd";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import { uploadDateAndSeason, DateAndSeason } from "./dataExchange";
interface FormDialogProps {
  open1: boolean;
  handleClickOpen: () => void;
  handleCloseDialog: () => void;
}
export const FormDialog: React.FC<FormDialogProps> = ({
  open1,
  handleClickOpen,
  handleCloseDialog,
}) => {
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate1, setEndDate1] = useState<string>("");
  const [endDate2, setEndDate2] = useState<string>("");
  const [newCollection, setNewCollection] = useState<boolean>(false);
  React.useEffect(() => {
    setOpen(open1);
  }, [open1]);

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(dateString as string);
  };
  const onChange2: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate1(dateString as string);
  };
  const onChange3: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate2(dateString as string);
  };
  const handleClose = () => {
    setOpen(false);
    handleCloseDialog();
  };
  const sendData = () => {
    const needSendData: DateAndSeason = {
      startDate: startDate,
      endDate1: endDate1,
      endDate2: endDate2,
      isNewCollection: newCollection,
    };
    if (startDate !== "" && endDate1 !== "" && endDate2 !== "") {
      uploadDateAndSeason(needSendData).catch((Error) => {
        console.log(Error);
      });
      handleClose();
    } else {
      alert("您还有没有填写的项目");
    }
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setNewCollection(checked);
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          style: { zIndex: 2 },
        }}
      >
        <div style={{ zIndex: 3 }}>
          <DialogTitle>评价时间设置</DialogTitle>
          <DialogContent>
            <DialogContentText>请设置时间：</DialogContentText>
            <Space direction="vertical">
              <div className="ant-picker-container" style={{ zIndex: 5 }}>
                <li style={{ marginTop: "10px", marginBottom: "10px" }}>
                  设置开始时间
                </li>
                <DatePicker
                  popupStyle={{ zIndex: 9999 }}
                  style={{ width: "200px" }}
                  onChange={onChange}
                />
                <li style={{ marginTop: "10px", marginBottom: "10px" }}>
                  设置互评结束时间
                </li>
                <DatePicker
                  popupStyle={{ zIndex: 9999 }}
                  style={{ width: "200px" }}
                  onChange={onChange2}
                />
                <li style={{ marginTop: "10px", marginBottom: "10px" }}>
                  设置复评结束时间
                </li>
                <DatePicker
                  popupStyle={{ zIndex: 9999 }}
                  style={{ width: "200px" }}
                  onChange={onChange3}
                />
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  是否开启新一轮收集
                  <Checkbox
                    checked={newCollection}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </Space>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>取消</Button>
            <Button onClick={sendData}>保存</Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
