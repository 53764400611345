import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { uploadUsersToDatabase } from "../home/dataExchange";

interface EmployeesDialogType {
  uid: string;
}
const positionList: string[] = ["tech", "bse", "techAndBse"];
export const EmployeesDialog: React.FC<EmployeesDialogType> = ({ uid }) => {
  const [open, setOpen] = React.useState(true);
  const [name, setName] = useState("");
  const [positionNumber, setPositionNumber] = useState<number | undefined | "">(
    ""
  );
  const [position, setPosition] = useState<string>();

  const handlePositionChange = (event: SelectChangeEvent<number>) => {
    setPositionNumber(event.target.value as number);
    setPosition(positionList[event.target.value as number]);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const mailAddress = window.localStorage.getItem("emailForSignIn");
    if (mailAddress && position) {
      await uploadUsersToDatabase(uid, mailAddress, name, position);
      window.localStorage.setItem("isSubmit", "true");
      window.location.href = "/evaluation/front";
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>账号详细</DialogTitle>
        <DialogContent>
          <DialogContentText>请输入姓名：</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="name"
            fullWidth
            variant="standard"
            value={name}
            onChange={handleNameChange}
          />
          <DialogContentText
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            请选择职位：
          </DialogContentText>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            label="Position"
            value={positionNumber}
            fullWidth
            onChange={handlePositionChange}
            name="position"
          >
            <MenuItem value={0}>技术人员</MenuItem>
            <MenuItem value={1}>BSE</MenuItem>
            <MenuItem value={2}>技术人员和BSE</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button type="submit">确定</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
