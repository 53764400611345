import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {initializeApp} from "firebase/app";
import {getAuth, onAuthStateChanged, sendSignInLinkToEmail, signOut} from "firebase/auth";
import {firebaseConfig} from "../../auth";
import {useEffect, useRef, useState} from "react";
import {fetchDataFromDatabase, uidNamePosition} from "../home";

initializeApp(firebaseConfig);

function Copyright(props: any) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://shuluntc.com/">
                Shuluntc.com
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function LoginPage() {
    const auth = getAuth();
    const [email, setEmail] = useState<string>(
        window.localStorage.getItem("emailForStorage") || ""
    );
    const signInButtonRef = useRef<HTMLButtonElement>(null);
    const [isSignInButtonDisabled, setIsSignInButtonDisabled] = useState(false);
    const currentUrlList: string[] = window.location.href.split("/");
    const toggleSignIn = async () => {
        setIsSignInButtonDisabled(true);
        if (email.split("@")[1] === "shuluntc.com") {
            if (auth.currentUser) {
                signOut(auth).catch((error) => {
                    handleError(error);
                });
            }
            const actionCodeSettings = {
                url:
                    "https://" + currentUrlList[2] + "/" + currentUrlList[3] + "/front/",
                handleCodeInApp: true,
            };
            signInButtonRef.current?.removeAttribute("disabled");
            signInButtonRef.current?.setAttribute("disabled", "true");
            sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                    window.localStorage.setItem("emailForSignIn", email);
                    window.localStorage.setItem("emailForStorage",email);
                    alert(`邮件已发送到 ${email} 请使用邮箱中的链接登录。`);
                    setIsSignInButtonDisabled(false);
                })
                .catch((error) => {
                    handleError(error);
                });
        } else {
            alert("请输入书论公司邮箱");
            window.location.href = "/evaluation/login";
        }
        const handleError = (error: any) => {
            alert("Error: " + error.message);
            console.error(error);
            setIsSignInButtonDisabled(false);
        };
    };

  useEffect(() => {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsSignInButtonDisabled(true);
        fetchDataFromDatabase(7, null, null, null).then((data) => {
          if (data) {
            const usersInformation = data as uidNamePosition[]
            for (let i = 0; i < usersInformation.length; i++) {
              if (user.uid === usersInformation[i].uid) {
                if (["tech", "bse", "techAndBse"].includes(usersInformation[i].position)) {
                  window.location.href = "/evaluation/front";
                } else if (["ceo", "cto"].includes(usersInformation[i].position)) {
                  window.location.href = "/evaluation/back";
                }
              }
            }
          }
        })
      }else {
          setIsSignInButtonDisabled(false);
      }
    })
  }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 1}}>
                        <TextField
                            type="text"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                            disabled={isSignInButtonDisabled}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            ref={signInButtonRef}
                            onClick={toggleSignIn}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}
