import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout } from "src/components/common";
import { Box, Typography } from "@mui/material";
import { BoardTitle, MiddleTitle } from "src/components/home";
import { useTranslation } from "react-i18next";

export const JoinPage: React.FC = () => {
  const { t } = useTranslation();
  const array = [
    {
      id: "1",
      name: t("careers.Technology.position_1.title"),
      duties: [
        t("careers.Technology.position_1.description.text_1"),
        t("careers.Technology.position_1.description.text_2"),
        t("careers.Technology.position_1.description.text_3"),
      ],
      requirement: [
        t("careers.Technology.position_1.requirements.text_1"),
        t("careers.Technology.position_1.requirements.text_2"),
        t("careers.Technology.position_1.requirements.text_3"),
        t("careers.Technology.position_1.requirements.text_4"),
        t("careers.Technology.position_1.requirements.text_5"),
        t("careers.Technology.position_1.requirements.text_6"),
        t("careers.Technology.position_1.requirements.text_7"),
        t("careers.Technology.position_1.requirements.text_8"),
      ],
      advantage: [
        t("careers.Technology.position_1.advantages.text_1"),
        t("careers.Technology.position_1.advantages.text_2"),
        t("careers.Technology.position_1.advantages.text_3"),
      ],
      type: "technology",
    },
    {
      id: "2",
      name: t("careers.Technology.position_2.title"),
      duties: [
        t("careers.Technology.position_2.description.text_1"),
        t("careers.Technology.position_2.description.text_2"),
        t("careers.Technology.position_2.description.text_3"),
      ],
      requirement: [
        t("careers.Technology.position_2.requirements.text_1"),
        t("careers.Technology.position_2.requirements.text_2"),
        t("careers.Technology.position_2.requirements.text_3"),
        t("careers.Technology.position_2.requirements.text_4"),
        t("careers.Technology.position_2.requirements.text_5"),
        t("careers.Technology.position_2.requirements.text_6"),
        t("careers.Technology.position_2.requirements.text_7"),
      ],
      advantage: [
        t("careers.Technology.position_2.advantages.text_1"),
        t("careers.Technology.position_2.advantages.text_2"),
        t("careers.Technology.position_2.advantages.text_3"),
      ],
      type: "technology",
    },
    {
      id: "3",
      name: t("careers.Technology.position_3.title"),
      duties: [
        t("careers.Technology.position_3.description.text_1"),
        t("careers.Technology.position_3.description.text_2"),
        t("careers.Technology.position_3.description.text_3"),
      ],
      requirement: [
        t("careers.Technology.position_3.requirements.text_1"),
        t("careers.Technology.position_3.requirements.text_2"),
        t("careers.Technology.position_3.requirements.text_3"),
        t("careers.Technology.position_3.requirements.text_4"),
        t("careers.Technology.position_3.requirements.text_5"),
        t("careers.Technology.position_3.requirements.text_6"),
      ],
      advantage: [
        t("careers.Technology.position_3.advantages.text_1"),
        t("careers.Technology.position_3.advantages.text_2"),
        t("careers.Technology.position_3.advantages.text_3"),
      ],
      type: "technology",
    },
    {
      id: "4",
      name: t("careers.Technology.position_4.title"),
      duties: [
        t("careers.Technology.position_4.description.text_1"),
        t("careers.Technology.position_4.description.text_2"),
        t("careers.Technology.position_4.description.text_3"),
      ],
      requirement: [
        t("careers.Technology.position_4.requirements.text_1"),
        t("careers.Technology.position_4.requirements.text_2"),
        t("careers.Technology.position_4.requirements.text_3"),
        t("careers.Technology.position_4.requirements.text_4"),
        t("careers.Technology.position_4.requirements.text_5"),
        t("careers.Technology.position_4.requirements.text_6"),
      ],
      advantage: [
        t("careers.Technology.position_4.advantages.text_1"),
        t("careers.Technology.position_4.advantages.text_2"),
        t("careers.Technology.position_4.advantages.text_3"),
        t("careers.Technology.position_4.advantages.text_4"),
      ],
      type: "technology",
    },
    {
      id: "5",
      name: t("careers.internship.position_1.title"),
      duties: [
        t("careers.internship.position_1.description.text_1"),
        t("careers.internship.position_1.description.text_2"),
        t("careers.internship.position_1.description.text_3"),
      ],
      requirement: [
        t("careers.internship.position_1.requirements.text_1"),
        t("careers.internship.position_1.requirements.text_2"),
        t("careers.internship.position_1.requirements.text_3"),
      ],
      advantage: [
        t("careers.internship.position_1.advantages.text_1"),
        t("careers.internship.position_1.advantages.text_2"),
        t("careers.internship.position_1.advantages.text_3"),
      ],
      type: "internship",
    },
    {
      id: "6",
      name: t("careers.internship.position_2.title"),
      duties: [
        t("careers.internship.position_2.description.text_1"),
        t("careers.internship.position_2.description.text_2"),
        t("careers.internship.position_2.description.text_3"),
        t("careers.internship.position_2.description.text_4"),
      ],
      requirement: [
        t("careers.internship.position_2.requirements.text_1"),
        t("careers.internship.position_2.requirements.text_2"),
        t("careers.internship.position_2.requirements.text_3"),
      ],
      advantage: [
        t("careers.internship.position_2.advantages.text_1"),
        t("careers.internship.position_2.advantages.text_2"),
        t("careers.internship.position_2.advantages.text_3"),
      ],
      type: "internship",
    },
    {
      id: "7",
      name: t("careers.language.position_1.title"),
      duties: [
        t("careers.language.position_1.description.text_1"),
        t("careers.language.position_1.description.text_2"),
        t("careers.language.position_1.description.text_3"),
      ],
      requirement: [
        t("careers.language.position_1.requirements.text_1"),
        t("careers.language.position_1.requirements.text_2"),
        t("careers.language.position_1.requirements.text_3"),
        t("careers.language.position_1.requirements.text_4"),
        t("careers.language.position_1.requirements.text_5"),
      ],
      advantage: [
        t("careers.language.position_1.advantages.text_1"),
        t("careers.language.position_1.advantages.text_2"),
      ],
      type: "language",
    },
    {
      id: "8",
      name: t("careers.language.position_2.title"),
      duties: [
        t("careers.language.position_2.description.text_1"),
        t("careers.language.position_2.description.text_2"),
        t("careers.language.position_2.description.text_3"),
        t("careers.language.position_2.description.text_4"),
      ],
      requirement: [
        t("careers.language.position_2.requirements.text_1"),
        t("careers.language.position_2.requirements.text_2"),
        t("careers.language.position_2.requirements.text_3"),
        t("careers.language.position_2.requirements.text_4"),
      ],
      advantage: [
        t("careers.language.position_2.advantages.text_1"),
        t("careers.language.position_2.advantages.text_2"),
        t("careers.language.position_2.advantages.text_3"),
      ],
      type: "language",
    },
  ];
  const divRef_1 = useRef<HTMLDivElement | null>(null);
  const divRef_2 = useRef<HTMLDivElement | null>(null);
  const [divToTopHeight1, setDivToTopHeight1] = useState<number>(0);
  const [divToTopHeight2, setDivToTopHeight2] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const handleScroll = useCallback(() => {
    setScrollHeight(window.scrollY);
  }, []);

  useEffect(() => {
    if (divRef_1.current) {
      const rect = divRef_1.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight1(distanceFromTop);
    }
    if (divRef_2.current) {
      const rect = divRef_2.current?.getBoundingClientRect();
      const distanceFromTop = rect.top;
      setDivToTopHeight2(distanceFromTop);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  return (
    <Layout>
      <Box
        sx={{
          width: "100%%",
          m: "0 auto",
          p: "40px 0 0",
          color: "#000",
          "@media screen and (max-width: 900px)": { padding: "30px 0 0" },
        }}
      >
        <Box
          maxWidth={1200}
          m="0 auto 50px"
          sx={{
            display: "flex",
            justifyContent: "center",
            "@media screen and (max-width: 900px)": {
              mb: "30px",
              "& p": { fontSize: 34 },
            },
          }}
        >
          <BoardTitle arrow="center" isLine>
            <Typography
              fontSize={50}
              lineHeight={1.2}
              letterSpacing={4}
              fontWeight="bold"
              textAlign="center"
              whiteSpace="normal"
            >
              {t("careers.title")}
            </Typography>
          </BoardTitle>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 380,
            background: `url(${require("src/assets/images/chengdu1.jpeg")}) no-repeat center center`,
            backgroundSize: "cover",
            backgroundPosition: "0% 80%",
            opacity: 0.6,
            "@media screen and (max-width: 400px)": {
              height: 300,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: "88%",
          maxWidth: 1200,
          height: "auto",
          background: "#fff",
          margin: "0 auto -68px",
          position: "relative",
          top: -68,
          zIndex: 9,
          "@media screen and (max-width: 900px)": { top: -38 },
        }}
      >
        <Box
          sx={{
            width: "85%",
            p: "0 0 80px",
            m: "0 auto",
            "& >.MuiBox-root:first-of-type": {
              height: 68,
              "& p": { lineHeight: "68px" },
            },
            "& >.MuiBox-root:nth-of-type(2)": { m: "70px 0 30px" },
            "& >.MuiBox-root:nth-of-type(4)": { m: "70px 0 30px" },
            "@media screen and (max-width: 900px)": {
              width: "100%",
              "& >.MuiBox-root:first-of-type": { width: "85%", m: "0 auto" },
              "& >.MuiBox-root:nth-of-type(2)": {
                m: "0px",
                " & p": { fontSize: 26 },
              },
              "& >.MuiBox-root:nth-of-type(4)": {
                m: "40px 0 0px",
                " & p": { fontSize: 26 },
              },
            },
          }}
        >
          <MiddleTitle text={t("careers.title")} />
          <Box
            pt={5}
            color="#000"
            sx={{
              width: "100%",
              "& a": {
                width: "100%",
                color: "#000",
                textDecoration: "none",
                display: "inline-block",
                "& >.MuiBox-root": {
                  display: "flex",
                  height: "auto",
                  fontSize: 14,
                  m: "0",
                  width: "100%",
                  "& .MuiBox-root": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  "& .MuiBox-root:first-of-type": {
                    flex: "0 0 25%",
                    p: "18px 30px",
                    borderTop: "1px solid #f5f5f5",
                    borderLeft: "1px solid #f5f5f5",
                    borderRight: "1px solid #f5f5f5",
                    position: "relative",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "break-spaces",
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      top: "0px",
                      left: "0",
                      width: "6px",
                      height: "1px",
                      background: "#000",
                    },
                  },
                  "& .MuiBox-root:last-of-type": {
                    flex: "1",
                    p: "18px 30px",
                    ml: 0,
                    borderTop: "1px solid #f5f5f5",
                    borderRight: "1px solid #f5f5f5",
                    "& dl": { width: "100%" },
                  },
                },
              },
              "& a:last-of-type": {
                "& .MuiBox-root:after": {
                  content: '""',
                  position: "absolute",
                  bottom: "1px",
                  left: "0",
                  width: "6px",
                  height: "1px",
                  background: "#000",
                },
                "& >.MuiBox-root": { borderBottom: "1px solid #f5f5f5" },
              },
              "& a:hover": {
                color: "#cb0400",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Box
              sx={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                mb: "70px",
                "@media screen and (max-width: 900px)": { mb: "40px" },
              }}
            >
              <BoardTitle arrow="center" isLine>
                <Typography
                  fontSize={32}
                  lineHeight={1.2}
                  letterSpacing={4}
                  fontWeight="bold"
                  textAlign="center"
                  whiteSpace="normal"
                >
                  {t("careers.Technology.title")}
                </Typography>
              </BoardTitle>
            </Box>
            <Box>
              {array
                .filter((i) => i.type === "technology")
                .map((item, index) => (
                  <a href={`/recruit/detail?id=${item.id}`} key={index}>
                    <Box display="flex" key={index}>
                      <Box>{item.name}</Box>
                      <Box>
                        {item.duties.slice(0, 2).map((i, index) => (
                          <Typography
                            key={index}
                            fontSize="13px !important"
                            lineHeight={1.8}
                            whiteSpace="normal"
                            // sx={{
                            //   overflow: index === 0 ? "unset" : "hidden",
                            //   textOverflow: index === 0 ? "unset" : "ellipsis",
                            //   whiteSpace: index === 0 ? "normal" : "nowrap",
                            // }}
                          >
                            {i}
                            {/* {index === 1 ? "..." : ""} */}
                          </Typography>
                        ))}
                        ...
                      </Box>
                    </Box>
                  </a>
                ))}
            </Box>
            <Box
              ref={divRef_1}
              sx={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                mt: "100px",
                mb: "70px",
                "@media screen and (max-width: 900px)": {
                  mt: "50px",
                  mb: "40px",
                },
              }}
            >
              <BoardTitle
                arrow="center"
                isLine={
                  window.innerHeight + scrollHeight > divToTopHeight1 + 60
                    ? true
                    : false
                }
              >
                <Typography
                  fontSize={32}
                  lineHeight={1.2}
                  letterSpacing={4}
                  fontWeight="bold"
                  textAlign="center"
                  whiteSpace="normal"
                >
                  {t("careers.internship.title")}
                </Typography>
              </BoardTitle>
            </Box>
            <Box>
              {array
                .filter((i) => i.type === "internship")
                .map((item, index) => (
                  <a href={`/recruit/detail?id=${item.id}`} key={index}>
                    <Box display="flex" key={index}>
                      <Box>{item.name}</Box>
                      <Box>
                        {item.duties.slice(0, 2).map((i, index) => (
                          <Typography
                            key={index}
                            fontSize="13px !important"
                            lineHeight={1.8}
                            whiteSpace="normal"
                          >
                            {i}
                          </Typography>
                        ))}
                        ...
                      </Box>
                    </Box>
                  </a>
                ))}
            </Box>
            <Box
              ref={divRef_2}
              sx={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                mt: "100px",
                mb: "70px",
                "@media screen and (max-width: 900px)": {
                  mt: "50px",
                  mb: "40px",
                },
              }}
            >
              <BoardTitle
                arrow="center"
                isLine={
                  window.innerHeight + scrollHeight > divToTopHeight2 + 60
                    ? true
                    : false
                }
              >
                <Typography
                  fontSize={32}
                  lineHeight={1.2}
                  letterSpacing={4}
                  fontWeight="bold"
                  textAlign="center"
                  whiteSpace="normal"
                >
                  {t("careers.language.title")}
                </Typography>
              </BoardTitle>
            </Box>
            <Box>
              {array
                .filter((i) => i.type === "language")
                .map((item, index) => (
                  <a href={`/recruit/detail?id=${item.id}`} key={index}>
                    <Box display="flex" key={index}>
                      <Box>{item.name}</Box>
                      <Box>
                        {item.duties.slice(0, 2).map((i, index) => (
                          <Typography
                            key={index}
                            fontSize="13px !important"
                            lineHeight={1.8}
                            whiteSpace="normal"
                          >
                            {i}
                          </Typography>
                        ))}
                        ...
                      </Box>
                    </Box>
                  </a>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default JoinPage;
