import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { keyframes } from "@emotion/react";

type ArrayType = { url: string; type: string; duration: number };

const fadeInAnimation = keyframes`
 0% {
  opacity: 1;
 }
 100% {
  opacity: 1;
 }
`;

export const Banner: React.FC = () => {
  const { t } = useTranslation();
  const lines = useMemo(
    () => [t("banner.text1"), t("banner.text2"), t("banner.text3")],
    [t]
  );
  const [visibleLines, setVisibleLines] = useState<string[]>(
    lines.map(() => "")
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const bannerArray: ArrayType[] = useMemo(
    () => [
      {
        url: require("src/assets/videos/introduce.mp4"),
        type: "video",
        duration: 31,
      },
    ],
    []
  );

  useEffect(() => {
    let maxLength = Math.max(...lines.map((line) => line.length));

    const timer = setInterval(() => {
      if (currentIndex < maxLength) {
        const updatedLines = lines.map((line) => {
          if (currentIndex < line.length) {
            return line.slice(0, currentIndex + 1);
          } else {
            return line;
          }
        });
        setVisibleLines(updatedLines);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(timer);
      }
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [visibleLines, lines, currentIndex]);

  useEffect(() => {
    let timeoutId: number;

    const playNextMedia = () => {
      if (carouselRef.current) {
        const current = currentSlide % bannerArray.length;

        const media = bannerArray[current];
        const player: HTMLMediaElement =
          carouselRef.current?.innerSlider?.list.querySelector(".video");

        const isLastMedia = current === bannerArray.length - 1;
        if (media?.type === "video" && player) {
          player.play();
        }
        timeoutId = window.setTimeout(() => {
          if (player) {
            player.pause();
          }
          if (isLastMedia) {
            carouselRef.current?.goTo(0);

            setCurrentSlide(0);
          } else {
            carouselRef.current?.next();

            setCurrentSlide((prevSlide) => prevSlide + 1);
          }
        }, media?.duration * 1000);
      }
    };
    playNextMedia();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [bannerArray, currentSlide]);

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        width: "100%",
        backgroundColor: "#000",
        color: "#fff",
        zIndex: 8,
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: 900,
          "& img": { width: "100%", height: "100%", objectFit: "cover" },
          "& .ant-carousel, .slick-slider": { height: 900 },
          "@media screen and (max-width: 900px)": {
            height: 390,
            "& .ant-carousel, .slick-slider": { height: 390 },
          },
          "@media screen and (max-width: 400px)": {
            height: 260,
            "& .ant-carousel, .slick-slider": { height: 260 },
          },
        }}
      >
        {bannerArray.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              height: 900,
              "& img": { width: "100%", height: "100%", objectFit: "cover" },
              "@media screen and (max-width: 900px)": { height: 390 },
              "@media screen and (max-width: 400px)": { height: 260 },
            }}
          >
            {item.type === "video" ? (
              <video
                autoPlay
                muted
                loop
                controls={false}
                className="video"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  height: "100%",
                }}
              >
                <source
                  src={item.url ?? ""}
                  type="video/mp4"
                  style={{ objectFit: "cover" }}
                />
              </video>
            ) : (
              <img src={item.url} alt="" width={2000} height={2000} />
            )}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          minWidth: 986,
          height: 900,
          pt: "336px",
          position: "absolute",
          left: "calc(50% - 493px)",
          textAlign: "center",
          zIndex: 3,
          "@media screen and (max-width: 900px)": {
            pt: "95px",
            "& p:first-of-type": { fontSize: 22 },
            "& p:nth-of-type(2)": { fontSize: 17 },
            "& p:last-of-type": { fontSize: 11.5 },
          },
        }}
      >
        {[
          { title: visibleLines[0], size: 50, weight: 300 },
          { title: visibleLines[1], size: 50, weight: 700 },
          { title: visibleLines[2], size: 20, weight: 700 },
        ].map((item, index) => (
          <Typography
            key={index}
            fontSize={item.size}
            fontWeight={item.weight}
            sx={{ animation: `${fadeInAnimation} 3s ease-in-out` }}
          >
            {item.title}
          </Typography>
        ))}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: 900,
          background:
            "url(https://www.baidu.jp/assets/img/index/cover_video_dot.png) repeat",
          backgroundSize: "16px 16px",
          position: "absolute",
          top: 0,
          left: 0,
          "@media screen and (max-width: 900px)": { height: 390 },
          "@media screen and (max-width: 400px)": { height: 260 },
        }}
      ></Box>
    </Stack>
  );
};

export default Banner;
